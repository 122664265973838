import React, { useContext } from "react";

import ym from "react-yandex-metrika";
import ReactGA from "react-ga4";
import ReactPixel from "../../includes/Metrics/ReactPixel";
import VkPixelCommon from "../../includes/Metrics/VkPixelCommon";
import { Analytics } from "../../includes/Metrics/Analytics";

import container from "../../container";
import { Context } from "../../context";
import PageStatisticService from "../../includes/Services/PageStatisticService";
import { Icon28ChevronRightOutline } from "@vkontakte/icons";
import usePageExternalLink from "../../includes/Hooks/usePageExternalLink";

type PageLinkProps = {
    id: string;
    pageId: string;
    blockId: string;
    url: string;
    text: string;
    className?: string;
    color: string;
    textColor?: string;
    isPreview: boolean;
};

const PageLink = (props: PageLinkProps) => {
    const LaunchParams = container.get("LaunchParams");

    const { pages, groupSiteData, popout } = useContext(Context);

    const {
        id,
        pageId,
        blockId,
        url,
        text,
        className = "",
        isPreview = false,
        color = "var(--vkui--color_accent_blue)",
        textColor = "#fff",
    } = props;

    const saveLead = (e) => {
        // В режиме предпросмотра не учитываем цели и не переходим по ссылке
        if (isPreview) {
            e.preventDefault();
            return false;
        }

        try {
            const groupData = groupSiteData.get();

            /**
             * Отправка целей в метрику
             */
            if (groupData.ya_metrica) {
                let params = {
                    page_id: pageId,
                    url: url,
                } as any;

                /**
                 * Достижение цели -  переход по ссылке
                 */
                ym("reachGoal", `page_url`, params);

                /**
                 * Достижение цели -  переход по ссылке на конкретной странице
                 */
                ym("reachGoal", `page_url_${pageId}`, params);
            }

            /**
             * Отправка целей в гугл-аналитику
             */
            if (groupData.ga_metrica) {
                // Простое достижение цели по типу
                ReactGA.event({
                    category: "Button",
                    action: "Click",
                    label: `page_url`,
                });

                ReactGA.event({
                    category: "Button",
                    action: "Click",
                    label: `page_url_${pageId}`,
                });
            }

            const vkPixel = groupData.vk_pixel

            /**
             * Отправка целей в пиксель вк
             */
            if (vkPixel) {
                // Общее событие по типу
                setTimeout(() => {
                    VkPixelCommon.event(vkPixel, 'page_url');
                }, 50);

                setTimeout(() => {
                    VkPixelCommon.event(vkPixel, `page_url_${pageId}`);
                }, 100);
                // Достижение цели - лид. Считать ли лид для ссылки?
                // VkPixelCommon.goal('lead', {value: 0});
            }

            /**
             * Отправка целей в GoogleTagManager
             */
            if (groupData.google_tag) {
                const utms = LaunchParams.getUtms();

                Analytics.push({
                    event: `page_url`,
                    vk_user_id: LaunchParams.params.vk_user_id,
                    ...utms,
                });

                Analytics.push({
                    event: `page_url_${pageId}`,
                    vk_user_id: LaunchParams.params.vk_user_id,
                    ...utms,
                });
            }

            /**
             * Отправка цели в FacebookPixel
             */
            if (groupData.fb_pixel) {
                ReactPixel.track(`page_url`);
                ReactPixel.track(`page_url_${pageId}`);
            }

            const goalData = {
                hit_id: pages.getHitId(),
                type: "url",
                vk_group_id: LaunchParams.params.vk_group_id,
                vk_user_id: LaunchParams.params.vk_user_id,
                vk_user_role: LaunchParams.params.vk_viewer_group_role,
                vk_ref: LaunchParams.params.vk_ref,
                vk_platform: LaunchParams.params.vk_platform,
                button_id: id,
                block_id: blockId,
                page_id: pageId,
            };

            PageStatisticService.goal(goalData).then((res) => {
                /** Some usefull stuff */
            });
        } catch (e) {
            console.log(e);
            /**
             * @TODO - log error
             */
        }
    };

    const [isExternalLink, showAlertOnClickExternalLink] = usePageExternalLink({
        url,
        isPreview,
        popout,
        confirmAction: saveLead
    })

    return (
        <>
            <a
                style={{
                    backgroundColor: color,
                    color: textColor,
                }}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={isExternalLink ? showAlertOnClickExternalLink : saveLead}
                className={`B-Button__link ${className}`}
            >
                <div className="B-Button__button-inner">
                    <span className="B-Button__button-text">
                        {text}
                    </span>
                    <span className="B-Button__button-icon">
                        <Icon28ChevronRightOutline width={18} />
                    </span>
                </div>
            </a>
        </>
    );
};

export default PageLink;
