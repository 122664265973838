import React, { useContext } from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import { Button } from "@vkontakte/vkui";
import { Icon28ChevronRightOutline } from "@vkontakte/icons";
import { fixContentToMarkDown } from "../../../includes/Helpers/TextToHtml";
import { BlockAlignment } from "../../../includes/Types/PageBlocks/IndentsAndAlignBlockPopup";
import { DEFAULT_BLOCK_TEXT_FONT_SIZE } from "../../../includes/Constants/PageBlockConstants";
import usePageExternalLink from "../../../includes/Hooks/usePageExternalLink";
import { Context } from "../../../context";

type TextBlockProps = {
    data: any;
    isPreview: boolean;
    blockPadding?: number;
    onButtonClick?: Function;
    blockAlignment?: BlockAlignment;
    textFontSize?: number;
};

const Text = (props: TextBlockProps) => {
    const {
        data,
        blockPadding = 20,
        onButtonClick,
        blockAlignment = "center",
        textFontSize = DEFAULT_BLOCK_TEXT_FONT_SIZE
    } = props;

    const { popout } = useContext(Context);

    const LinkRenderer = (props) => {
        const [isExternalLink, showAlertOnClickExternalLink] = usePageExternalLink({
            url: props.href,
            isPreview: false,
            popout,
            confirmAction: () => {}
        })

        return (
            <a
                href={props.href}
                onClick={isExternalLink ? showAlertOnClickExternalLink : () => {}}
                target="_blank"
            >
                {props.children}
            </a>
        );
    }

    return (
        <div
            className={`B-Block B-Text ${blockAlignment}`}
            style={{
                paddingTop: `${blockPadding}px`,
                paddingBottom: `${blockPadding}px`,
                fontSize: `${textFontSize}em`,
            }}
        >
            {data.content.title && (
                <div className="B-Text__title B-Block__title">
                    {data.content.title}
                </div>
            )}
            {data.content.text && (
                <div className="B-Text__text B-Block__subtitle">
                    <ReactMarkdown
                        plugins={[gfm]}
                        allowDangerousHtml={true}
                        linkTarget={"_blank"}
                        renderers={{
                            link: LinkRenderer
                        }}
                    >
                        {fixContentToMarkDown(data.content.text)}
                    </ReactMarkdown>
                </div>
            )}
            {data.button && (
                <div className="B-Text__button">
                    <Button
                        size="l"
                        onClick={() => {
                            onButtonClick();
                        }}
                    >
                        <div className="B-Text__button-inner">
                            <span className="B-Text__button-text">
                                {data.button.text}
                            </span>
                            <span className="B-Text__button-icon">
                                <Icon28ChevronRightOutline width={18} />
                            </span>
                        </div>
                    </Button>
                </div>
            )}
        </div>
    );
};

export default Text;
