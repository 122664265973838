import React, { useEffect, useState } from "react";
import { useTimer } from "react-compound-timer";
import { simpleTextToHtml } from "../../../includes/Helpers/TextToHtml";
import DateTimeHelper from "../../../includes/Helpers/DateTimeHelper";
import PageButton from "../../hoc/PageButton";
import PageLink from "../../hoc/PageLink";
import {
    DAYS,
    DEFAULT_OVERLAY_OPACITY_VALUE,
} from "../../../includes/Constants";
import { BlockAlignment } from "../../../includes/Types/PageBlocks/IndentsAndAlignBlockPopup";
import { DEFAULT_BLOCK_TEXT_FONT_SIZE } from "../../../includes/Constants/PageBlockConstants";

type TimerDateProps = {
    data: any;
    isPreview?: boolean;
    blockPadding?: number;
    platform: string;
    blockAlignment?: BlockAlignment;
    textFontSize?: number;
};

const dateTimeHelper = new DateTimeHelper();

const TimerFixed = (props: TimerDateProps) => {
    const {
        data,
        isPreview = false,
        blockPadding = 80,
        platform,
        blockAlignment = "center",
        textFontSize = DEFAULT_BLOCK_TEXT_FONT_SIZE
    } = props;

    const backgroundStyles: any = {};

    if (data.background && data.background.url) {
        backgroundStyles.background = `url(${data.background.url})`;
        backgroundStyles.backgroundSize = "cover";
        backgroundStyles.backgroundPosition = "center";
    }

    const start_time = data.content.start_time;
    const end_time = data.content.end_time;
    const weekdays = data.content.weekdays;

    const {
        value,
        controls: { setTime, start: startTimer },
    } = useTimer({
        direction: "backward",
        startImmediately: true,
    });

    const start = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        start_time.split(":")[0],
        start_time.split(":")[1]
    ).getTime();

    const [isStartInteractive, setIsStartInteractive] = useState(
        Date.now() >= start
    );

    const end = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        end_time.split(":")[0],
        end_time.split(":")[1]
    ).getTime();

    const dateDifference = end - Date.now();

    useEffect(() => {
        const checkStartInteractiveInterval = setInterval(() => {
            if (isStartInteractive) {
                clearInterval(checkStartInteractiveInterval);
            }

            if (!isStartInteractive) {
                if (Date.now() >= start) {
                    setIsStartInteractive(true);
                    clearInterval(checkStartInteractiveInterval);
                }
            }
        }, 1000);

        return () => clearInterval(checkStartInteractiveInterval);
    }, []);

    useEffect(() => {
        let isActiveCurrentDay;
        DAYS.forEach((day, idx) => {
            if (day.id === new Date().getDay()) {
                isActiveCurrentDay = weekdays[idx];
            }
        });

        if (isActiveCurrentDay && dateDifference > 0 && Date.now() >= start) {
            setTime(dateDifference);
            startTimer();
        } else {
            setTime(0);
        }
    }, [isStartInteractive]);

    const renderButton = () => {
        if (
            data.button.hide_button &&
            value.h <= 0 &&
            value.m <= 0 &&
            value.s <= 0
        ) {
            return null;
        }

        if (data.button.action === "url") {
            return <div className="B-Cover__button">{getPageLink()}</div>;
        } else {
            return <div className="B-Cover__button">{getPageButton()}</div>;
        }
    };

    const getPageButton = () => {
        return (
            <PageButton
                isPreview={isPreview}
                pageId={data.page_id}
                blockId={data.id}
                data={data.button}
            />
        );
    };

    const getPageLink = () => {
        return (
            <PageLink
                className="B-Button__link_product"
                isPreview={isPreview}
                id={data.button.id}
                pageId={data.page_id}
                blockId={data.id}
                url={data.button.url}
                text={data.button.text}
                color={data.button.color}
                textColor={data.button.text_color}
            />
        );
    };

    const renderTitle = () => {
        if (data.has_title) {
            return (
                <>
                    {data.content.title && (
                        <div className="B-Timer__title B-Block__title">
                            {data.content.title}
                        </div>
                    )}
                    {data.content.text && (
                        <div className="B-Timer__text B-Block__subtitle">
                            {simpleTextToHtml(data.content.text)}
                        </div>
                    )}
                </>
            );
        }
    };

    const setClassIfBlockEmpty = () => {
        if (!data.has_title && !data.has_button && !data.has_background) {
            return "B-Timer-empty";
        }
        return "";
    };

    const overlayOpacity =
        data.background.overlay_opacity || data.background.overlay_opacity === 0
            ? data.background.overlay_opacity
            : DEFAULT_OVERLAY_OPACITY_VALUE;

    const isFullscreen =
        data.meta && data.meta.is_fullscreen ? data.meta.is_fullscreen : false;

    return (
        <div
            className={`B-Block B-Timer ${platform} ${setClassIfBlockEmpty()} ${blockAlignment} ${
                isFullscreen && platform !== "desktop_web" ? "fullscreen" : ""
            }`}
            style={{
                fontSize: `${textFontSize}em`,
            }}
        >
            {data.has_background && data.background.url && (
                <div className="B-Timer__background" style={backgroundStyles}>
                    <div
                        style={{
                            background: `rgba(0, 0, 0, ${overlayOpacity}%)`,
                        }}
                        className="GroupBannersForm__overlay"
                    ></div>
                </div>
            )}
            <div
                className={`B-Timer__content ${
                    !data.has_background ? "B-Timer__content_bg_none" : ""
                }`}
                style={{
                    paddingTop: `${blockPadding}px`,
                    paddingBottom: `${blockPadding}px`,
                }}
            >
                {renderTitle()}
                <div className="timer timer-tiles">
                    <div className="timer__content">
                        <div className="timer__row">
                            <div className="timer__block">
                                <div className="timer__col">{value.d}</div>
                                <span>
                                    {dateTimeHelper.getDeclarationOfDays(
                                        value.d
                                    )}
                                </span>
                            </div>

                            <div className="timer__block">
                                <div className="timer__col">{value.h}</div>
                                <span>
                                    {dateTimeHelper.getDeclarationOfHours(
                                        value.h
                                    )}
                                </span>
                            </div>

                            <div className="timer__block">
                                <div className="timer__col">{value.m}</div>
                                <span>
                                    {dateTimeHelper.getDeclarationOfMinutes(
                                        value.m
                                    )}
                                </span>
                            </div>

                            <div className="timer__block">
                                <div className="timer__col">{value.s}</div>
                                <span>
                                    {dateTimeHelper.getDeclarationOfSeconds(
                                        value.s
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                {data.has_button && data.button.text && renderButton()}
            </div>
        </div>
    );
};

export default TimerFixed;
