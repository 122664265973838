import React, { useState } from "react";
import bridge from "@vkontakte/vk-bridge";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Footer } from "@vkontakte/vkui";
import { Swiper, SwiperSlide } from "swiper/react";
import { simpleTextToHtml } from "../../../../includes/Helpers/TextToHtml";
import { BlockAlignment } from "../../../../includes/Types/PageBlocks/IndentsAndAlignBlockPopup";

import "swiper/swiper-bundle.css";
import { DEFAULT_BLOCK_TEXT_FONT_SIZE } from "../../../../includes/Constants/PageBlockConstants";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const defaultResizer = {
    // Объект, управляющий высотой приложения на десктопе
    setLandingHeight: () => {},
};

type ImagesGalleryPropsType = {
    data: any;
    hasButtons?: boolean;
    blockPadding?: number;
    resizer?: any;
    platform?: string;
    isEdit?: boolean;
    blockAlignment?: BlockAlignment;
    textFontSize?: number;
};

const ImagesGallery = (props: ImagesGalleryPropsType) => {
    const {
        data,
        isEdit = true,
        hasButtons = true,
        platform,
        resizer = defaultResizer,
        blockPadding = 0,
        blockAlignment = "center",
        textFontSize = DEFAULT_BLOCK_TEXT_FONT_SIZE
    } = props;

    const [swiper, setSwiper] = useState(null);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

    if (!data.items) data.items = [];

    const imgRenderer = () => {
        resizer.setLandingHeight();
    };

    const imgStartRenderer = () => {
        requestAnimationFrame(imgRenderer);
    };

    const showFullImage = (image, index) => {
        bridge.send("VKWebAppShowImages", {
            images: data.items.map((i) => i.url),
            start_index: index,
        });
    };

    const renderImage = (item, index, type = "slider") => {
        let img = (
            <div
                style={{
                    background: `url(${item.url}) center center / cover no-repeat`,
                }}
                className="B-Images__img"
            />
        );

        if (type === "single") {
            img = (
                <div className="B-Images__single">
                    <img
                        alt=""
                        src={item.url}
                        onLoad={(e) => {
                            requestAnimationFrame(imgStartRenderer);
                        }}
                    />
                </div>
            );
        }

        // Просмотр полного изображения для платформ, поддерживающих нативный просмотр
        //bridge.supports("VKWebAppShowImages")
        if (bridge.supports("VKWebAppShowImages")) {
            return (
                <div
                    onClick={() => {
                        showFullImage(item, index);
                    }}
                    className="B-Images__img-wrap"
                >
                    {img}
                </div>
            );
        } else {
            return <div className="B-Images__img-wrap">{img}</div>;
        }
    };

    const renderSingleImage = () => {
        const image = data.items[0];
        return renderImage(image, 0, "single");
    };

    const renderSlider = () => {
        return (
            <div data-role className="B-Images__slider">
                {hasButtons && currentSlideIndex > 0 ? (
                    <div
                        className="B-Images__slider-button B-Images__slider-button_left"
                        onClick={() => {
                            if (swiper) swiper.slidePrev();
                        }}
                    ></div>
                ) : null}

                {hasButtons && currentSlideIndex < data.items.length - 1 ? (
                    <div
                        className="B-Images__slider-button B-Images__slider-button_right"
                        onClick={() => {
                            if (swiper) swiper.slideNext();
                        }}
                    ></div>
                ) : null}

                <Swiper
                    lazy={true}
                    onInit={(instance) => {
                        setSwiper(instance);
                    }}
                    onSlideChange={(instance) => {
                        setCurrentSlideIndex(instance.activeIndex);
                    }}
                    preloadImages={false}
                    pagination={{
                        type: "fraction",
                        renderFraction: (currentClass, totalClass) => {
                            return `<span class="${currentClass}"></span> из <span class="${totalClass}"></span>`;
                        },
                    }}
                >
                    {data.items.map((item, index) => {
                        return (
                            <SwiperSlide key={item.uuid}>
                                {renderImage(item, index)}
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
                <div className="swiper-pagination"></div>
            </div>
        );
    };

    const hasHead = !!data.content.title || !!data.content.text;

    return (
        <div
            className={`B-Block B-Images ${blockAlignment}`}
            style={{
                paddingTop: `${blockPadding}px`,
                paddingBottom: `${blockPadding}px`,
                fontSize: `${textFontSize}em`,
            }}
        >
            {hasHead && (data.has_title === undefined || data.has_title) && (
                <div className="B-Block__head" style={{ paddingTop: 20 }}>
                    {data.content.title && (
                        <div className="B-Block__title">
                            {data.content.title}
                        </div>
                    )}
                    {data.content.text && (
                        <div className="B-Block__subtitle">
                            {simpleTextToHtml(data.content.text)}
                        </div>
                    )}
                </div>
            )}
            <div className="B-Images__items">
                {data.items.length === 1 ? renderSingleImage() : renderSlider()}
            </div>
            {
                false === hasHead && data.items.length === 0 ? (
                    <div style={{ padding: 20 }}>
                        {isEdit && <Footer>Пустой блок "Изображение"</Footer>}
                    </div>
                ) : null // На случай, если каким то образом удалили вообще весь контент из блока
            }
        </div>
    );
};

export default ImagesGallery;
