import React, { Component } from "react";
import { Button, Alert } from "@vkontakte/vkui";

import AlertSubscriptionSuccess from "./Modals/Alert/AlertSubscriptionSuccess";
import AlertPayments from "./Modals/Alert/AlertPayments";

import bridge, { PersonalCardType } from "@vkontakte/vk-bridge";
import ym from "react-yandex-metrika";
import ReactGA from "react-ga4";
import ReactPixel from "../includes/Metrics/ReactPixel";
import VkPixelCommon from "../includes/Metrics/VkPixelCommon";
import container from "../container";
import CookieProvider from "../includes/Helpers/CookieProvider";
import LocalStorageProvider from "../includes/Helpers/LocalStorageProvider";
import DomHelpers from "../includes/Helpers/DomHelpers";

import {
    isVkSubscriptionUrl,
    parseSubscriptionIdFromUrl,
    parsePageIdFromUrl,
    getUtmParamsString,
    replaceVariable,
    isVkPageUrl,
    getDecodedWithSpecialCharsString,
    setHash,
} from "../includes/Helpers/Helpers";

import { Analytics } from "../includes/Metrics/Analytics";

import AlertGetEmail from "./Modals/Alert/AlertGetEmail";
import { Context } from "../context";
import Logger from "../includes/Services/Logger";
import { Icon28ChevronRightOutline } from "@vkontakte/icons";
import * as Sentry from "@sentry/react";

const pay_icons = {
    yandex: require("../images/yoomoney.svg"),
    card: require("../images/card.svg"),
    vk: require("../images/vk.svg"),
    mobile: require("../images/mobile.svg"),
    unitpay: require("../images/unitpay.svg"),
    robokassa: require("../images/robokassa.svg"),
    prodamus: require("../images/prodamus.svg"),
};

let _Subscribes = container.get("SubscribeService");
let variablesService = container.get("VariablesService");

type SubscriptionButtonProps = {
    mainPopout: any;
    opts: any;
    UrlParams: any;
    group: any;
    user: any;
    hash: any;
    onSuccess: any;
    isPreview: any;
    color: any;
    textColor: any;
    text: any;
    unsubscribeText: any;
    setForceSubscriptionPage: any;
    isDisabledTimerBtn?: boolean;
};

type SubscriptionButtonState = {
    opts: any;
    windowWidth: any;
    windowHeight: any;
    isPending: any;
    loading?: any;
    success?: any;
};

class SubscriptionButton extends Component<
    SubscriptionButtonProps,
    SubscriptionButtonState
> {
    static contextType = Context;

    start_subscribe: boolean;
    start_pay: boolean;
    count_check_order: number;
    group_pay_id: number;
    logger: Logger;

    constructor(props) {
        super(props);

        this.start_subscribe = false;
        this.start_pay = false;
        this.count_check_order = 0;
        this.group_pay_id = 0;

        this.state = {
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            opts: props.opts,
            isPending: false,
        };

        this.logger = container.get("logger");

        bridge.subscribe((e: any) => {
            if (e.detail) {
                if (this.start_subscribe) {
                    switch (e.detail.type) {
                        /**
                         * Событие при успешном получении разрешения на отправку сообщений от сообщества
                         */
                        case "VKWebAppAllowMessagesFromGroupResult":
                            this.props.mainPopout.close();
                            this.start_subscribe = false;
                            if (
                                this.state.opts.pay &&
                                (!this.props.opts.paid ||
                                    this.props.opts.pay_period > 0)
                            )
                                this.prepare_pay();
                            else this.subscribe();
                            break;

                        /**
                         * Событие при отказе на отправку сообщений от сообщества
                         */
                        case "VKWebAppAllowMessagesFromGroupFailed":
                            this.props.mainPopout.close();
                            this.start_subscribe = false;
                            break;

                        /**
                         * Событие при сворачивании сервиса (кнопка "Закрыть"|"Назад")
                         */
                        case "VKWebAppViewHide":
                            this.props.mainPopout.close();
                            this.start_subscribe = false;
                            break;

                        /**
                         * Событие при восстановлении приложения из кэша
                         */
                        case "VKWebAppViewRestore":
                            this.start_subscribe = false;
                            break;

                        default:
                            break;
                    }
                }
                if (this.start_pay) {
                    switch (e.detail.type) {
                        case "VKWebAppOpenPayFormResult":
                            if (
                                e &&
                                e.detail &&
                                e.detail.data &&
                                e.detail.data.status
                            ) {
                                this.props.mainPopout.loading();

                                this.check_order(this.group_pay_id, true);
                            } else {
                                this.clearCheckOrderTimeout();
                            }
                            break;
                        case "VKWebAppOpenPayFormFailed":
                            this.props.mainPopout.close();
                            this.start_pay = false;
                            this.clearCheckOrderTimeout();
                            break;
                        default:
                    }
                }
            }
        });
    }

    clearCheckOrderTimeout() {
        clearTimeout(this.check_order_timer_id);
        this.check_order_timer_id = null;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.opts.subscribed !== this.props.opts.subscribed) {
            this.setState({ opts: this.props.opts });
        }
    }

    prepare_subscribe() {
        try {
            if (!this.start_subscribe) {
                this.start_subscribe = true;

                if (bridge.supports("VKWebAppAllowMessagesFromGroup")) {
                    bridge.send("VKWebAppAllowMessagesFromGroup", {
                        group_id: parseInt(this.props.group.vk_group_id),
                        key: this.state.opts.subscription_id,
                    });
                } else {
                    this.start_subscribe = false;
                    this.showNotAllowedMessagesError();
                    this.logger.error(
                        {
                            message:
                                "Event VKWebAppAllowMessagesFromGroup not supported",
                            code: 998,
                        },
                        "bridge_error",
                        "Subscription.jsx"
                    );
                }
            }
        } catch (e) {
            this.logger.error(
                { code: 9053, message: e.message },
                "subscribe_error",
                "Subscription.jsx"
            );
            Sentry.captureException(e);
            console.log(e);
        }
    }

    async prepare_unit_pay(order_data) {
        this.props.mainPopout.close();

        /**
         * @TODO - по нажатию на кнопку "Отмена" - возвращать на выбор способа отплаты, а не закрывать модальное окно
         */

        const user_email = LocalStorageProvider.getItem("user_email");
        const closeModal = this.props.mainPopout.close;

        setTimeout(() => {
            this.props.mainPopout.open(
                <AlertGetEmail
                    onSubmit={(email) => {
                        if (!user_email) {
                            LocalStorageProvider.setItem("user_email", email);
                        }
                    }}
                    onCancel={closeModal}
                    defaultValue={user_email}
                    unitPayUrl={order_data.unitpay_data.url}
                />
            );
        }, 100);
    }

    check_order_timer_id = null;

    check_order(group_pay_id, force = false) {
        const isShowedPaymentsPage =
            this.context.payment.getIsShowedPaymentsPage();

        if ((isShowedPaymentsPage && this.start_pay) || force) {
            this.check_order_timer_id = setTimeout(() => {
                _Subscribes.checkOrder(
                    {
                        vk_group_id: this.props.group.vk_group_id,
                        vk_user_id: this.props.user.id,
                        subscription_id: this.state.opts.subscription_id,
                        hash: this.props.hash,
                        params: this.props.UrlParams.params,
                        group_pay_id: group_pay_id,
                    },
                    (result) => {
                        if (result.success) {
                            const status = parseInt(result.status);

                            if (status === 1) {
                                const { UrlParams, onSuccess } = this.props;

                                this.handleSubscribe(
                                    result,
                                    UrlParams,
                                    group_pay_id,
                                    onSuccess,
                                    status
                                );

                                this.start_pay = false;
                                this.clearCheckOrderTimeout();
                                this.context.payment.setIsPaidSubscription(
                                    true
                                );
                            } else {
                                this.check_order(group_pay_id, force);
                            }
                        } else {
                            this.props.mainPopout.error(result.message);
                        }
                    }
                );
            }, 2000);
        }
    }

    vk_pay(vkpay_data_params) {
        if (bridge.supports("VKWebAppOpenPayForm")) {
            bridge.send("VKWebAppOpenPayForm", {
                app_id: parseInt(this.props.UrlParams.params.vk_app_id),
                ...vkpay_data_params,
            });
        } else {
            console.log("Event VKWebAppOpenPayForm not supported");
        }
    }

    create_order() {
        this.props.mainPopout.loading();

        _Subscribes.createOrder(
            {
                vk_group_id: this.props.group.vk_group_id,
                vk_user_id: this.props.user.id,
                subscription_id: this.state.opts.subscription_id,
                hash: this.props.hash,
                params: this.props.UrlParams.params,
            },
            (result) => {
                if (result.success) {
                    if (result.group_pay_id) {
                        this.group_pay_id = Number(result.group_pay_id);

                        if (
                            result.vkpay_data ||
                            result.unitpay_data ||
                            result.yoomoney_data ||
                            result.prodamus_data ||
                            result.robokassa_data
                        ) {
                            this.start_pay = true;
                            let need_select = false;
                            let methods = [];

                            for (let method of this.props.group.data
                                .pay_methods) {
                                if (parseInt(method.group_pay_system_id) !== 1)
                                    need_select = true;
                                switch (parseInt(method.group_pay_system_id)) {
                                    // VK Pay
                                    case 1:
                                        methods.push({
                                            icon: pay_icons[method.icon],
                                            link: {
                                                onClick: () => {
                                                    this.start_pay = true;

                                                    this.vk_pay(
                                                        result.vkpay_data.params
                                                    );

                                                    if (
                                                        !this
                                                            .check_order_timer_id
                                                    ) {
                                                        this.check_order(
                                                            result.group_pay_id
                                                        );
                                                    }
                                                },
                                            },
                                            title: method.title,
                                            description: method.description,
                                        });
                                        break;
                                    // ЮMoney
                                    case 2:
                                        methods.push({
                                            icon: pay_icons[method.icon],
                                            link: {
                                                href: result.yoomoney_data.url,
                                                target: "_blank",
                                                onClick: () => {
                                                    this.start_pay = true;

                                                    if (
                                                        !this
                                                            .check_order_timer_id
                                                    ) {
                                                        this.check_order(
                                                            result.group_pay_id
                                                        );
                                                    }
                                                },
                                            },
                                            title: method.title,
                                            description: method.description,
                                        });
                                        break;
                                    // UnitPay
                                    case 4:
                                        methods.push({
                                            id: method.group_pay_system_id,
                                            icon: pay_icons[method.icon],
                                            link: {
                                                onClick: () => {
                                                    this.start_pay = true;

                                                    this.prepare_unit_pay(
                                                        result
                                                    );

                                                    if (
                                                        !this
                                                            .check_order_timer_id
                                                    ) {
                                                        this.check_order(
                                                            result.group_pay_id
                                                        );
                                                    }
                                                },
                                            },
                                            title: method.title,
                                            description: method.description,
                                        });
                                        break;
                                    // Robokassa
                                    case 5:
                                        methods.push({
                                            id: method.group_pay_system_id,
                                            icon: pay_icons[method.icon],
                                            link: {
                                                href: result.robokassa_data.url,
                                                target: "_blank",
                                                onClick: () => {
                                                    this.start_pay = true;

                                                    if (
                                                        !this
                                                            .check_order_timer_id
                                                    ) {
                                                        this.check_order(
                                                            result.group_pay_id
                                                        );
                                                    }
                                                },
                                            },
                                            title: method.title,
                                            description: method.description,
                                        });
                                        break;

                                    // Prodamus
                                    case 6:
                                        methods.push({
                                            id: method.group_pay_system_id,
                                            icon: pay_icons[method.icon],
                                            link: {
                                                href: result.prodamus_data.url,
                                                target: "_blank",
                                                onClick: () => {
                                                    this.start_pay = true;

                                                    if (
                                                        !this
                                                            .check_order_timer_id
                                                    ) {
                                                        this.check_order(
                                                            result.group_pay_id
                                                        );
                                                    }
                                                },
                                            },
                                            title: method.title,
                                            description: method.description,
                                        });
                                        break;
                                    default:
                                }
                            }

                            if (need_select) {
                                setHash({
                                    list: "pages",
                                    view: "pay-methods",
                                });

                                this.context.payment.setPaymentMethods(methods);
                                this.context.payment.setIsShowedPaymentsPage(
                                    true
                                );
                                this.context.payment.setIsPaidSubscription(
                                    false
                                );
                            } else {
                                this.props.mainPopout.close();
                                this.vk_pay(result.vkpay_data.params);

                                this.start_pay = true;

                                if (!this.check_order_timer_id) {
                                    this.check_order(result.group_pay_id, true);
                                }
                            }
                        } else {
                            this.props.mainPopout.open(
                                <AlertPayments
                                    communityChatUrl={`https://vk.com/im?sel=-${this.props.group.vk_group_id}`}
                                    onClose={this.props.mainPopout.close}
                                />
                            );
                        }
                    } else {
                        this.subscribe();
                    }
                } else {
                    this.props.mainPopout.error(result.message);
                }
            }
        );
    }

    prepare_pay() {
        this.props.mainPopout.loading();
        this.count_check_order = 0;
        if (
            this.props.group.data.pay_methods &&
            this.props.group.data.pay_methods.length > 0
        ) {
            this.create_order();
        } else
            this.props.mainPopout.open(
                <AlertPayments
                    communityChatUrl={`https://vk.com/im?sel=-${this.props.group.vk_group_id}`}
                    onClose={this.props.mainPopout.close}
                />
            );

        DomHelpers.scrollToModal();
    }

    changeRefVariable(href, utms) {
        let variable, utm_params_string;

        if (href.includes("%25ref%25")) {
            variable = "%25ref%25";
            utm_params_string = encodeURIComponent(getUtmParamsString(utms));
        } else if (href.includes("%ref%")) {
            variable = "%ref%";
            utm_params_string = getUtmParamsString(utms);
        }

        return replaceVariable(href, variable, utm_params_string);
    }

    subscribe(group_pay_id = null) {
        try {
            const { UrlParams, onSuccess } = this.props;
            const user_email = LocalStorageProvider.getItem("user_email");

            const request_data = {
                vk_group_id: this.props.group.vk_group_id,
                vk_user_id: this.props.user.id,
                subscription_id: this.state.opts.subscription_id,
                hash: this.props.hash,
                params: {
                    ...this.props.UrlParams.params,
                    source: "page",
                },
                group_pay_id: group_pay_id,
            };

            const gcid = CookieProvider.getCookie("_ga");
            const ym_uid = CookieProvider.getCookie("_ym_uid");
            const roistat = CookieProvider.getCookie("roistat_visit");

            if (gcid) {
                request_data.params.gcid = gcid;
            }

            if (ym_uid) {
                request_data.params.ym_uid = ym_uid;
            }

            if (roistat) {
                request_data.params.roistat_visit = roistat;
            }

            if (user_email) {
                request_data.params.user_email = user_email;
            }

            this.props.mainPopout.loading();
            _Subscribes.subscribe(request_data, (result) => {
                this.handleSubscribe(
                    result,
                    UrlParams,
                    group_pay_id,
                    onSuccess
                );
            });
        } catch (e) {
            this.logger.error(
                { code: 9056, message: e.message },
                "subscribe_error",
                "Subscription.jsx"
            );
            Sentry.captureException(e);
        }
    }

    async handleSubscribe(
        result,
        UrlParams,
        group_pay_id,
        onSuccess,
        status = 1
    ) {
        if (result.success && status) {
            this.props.mainPopout.close();

            let title, desc, btn, href, openInNewTab;
            this.start_pay = false;
            title = "Вы успешно подписаны!";
            desc = "Рассылка скоро придет Вам в личные сообщения.";

            const phoneSending = this.props.opts.phone_sending;
            const emailSending = this.props.opts.email_sending;

            if (phoneSending || emailSending) {
                await this.setLeadPersonalInfo(
                    UrlParams.params,
                    phoneSending,
                    emailSending
                );
            }

            // Если есть урл после подписки
            if (result.url_after_subscribing) {
                let isVkUrl = isVkSubscriptionUrl(result.url_after_subscribing);
                openInNewTab = false;

                btn = "Продолжить";
                href = result.url_after_subscribing;

                if (
                    isVkUrl &&
                    result.url_after_subscribing.indexOf('force') >= 0
                ) {
                    openInNewTab = true
                }

                if (href.includes("%25ref%25") || href.includes("%ref%")) {
                    href = this.changeRefVariable(href, UrlParams.getUtms());
                }

                // Если редирект без подтверждения - выполняем его только для web платформ или для внутренних ссылок ВК
                if (
                    result.disabled_notify === 1 &&
                    (isVkUrl || !this.isMobileApp())
                ) {
                    window.top.location.href = href;

                    if (isVkPageUrl(result.url_after_subscribing)) {
                        let pageId = parsePageIdFromUrl(
                            result.url_after_subscribing
                        );

                        window.location.hash = `page=${pageId}`;
                        window.location.reload();
                    }

                    // Если урл после подписки - это другая подписная страница (иначе обновится только хэш)
                    if (isVkUrl) {
                        let subsriptionId = parseSubscriptionIdFromUrl(
                            result.url_after_subscribing
                        );

                        window.location.hash = `s=${subsriptionId}`;
                        window.location.reload();
                    }
                } else if (result.disabled_notify === 1 && this.isMobileApp()) {
                    this.showSubscriptionSuccessAlert({
                        actions: [],
                        title,
                        desc,
                        btn,
                        result,
                        href,
                        openInNewTab
                    });
                }

                // Если после подписки есть добавление в бота, то берем ссылку на чат с сообществом
            } else if (this.props.opts.has_bot) {
                btn = "Посмотреть сообщение";
                href = result.url_messages;
            }

            const actions = [];

            if (
                !result.url_after_subscribing ||
                (group_pay_id && !result.url_after_subscribing)
            ) {
                actions.push({
                    title: "ОK",
                    autoClose: true,
                    mode: "cancel",
                });
            }

            if (!result.disabled_notify) {
                this.showSubscriptionSuccessAlert({
                    actions,
                    title,
                    desc,
                    result,
                    href,
                    btn,
                    openInNewTab
                });
            }

            this.props.opts.subscribed = true;
            this.props.opts.paid = true;
            this.props.opts.count = this.props.opts.count + 1;

            if (this.props.opts.pay_period > 0)
                this.props.opts.pay_expired = result.pay_expired;

            this.setState({
                loading: false,
                success: true,
                opts: this.props.opts,
            });

            try {
                onSuccess && onSuccess();
            } catch (e) {}

            try {
                if (this.props.group.data.ya_metrica) {
                    ym("reachGoal", "subscribe", {
                        subscription_id: this.state.opts.subscription_id,
                    });
                    ym(
                        "reachGoal",
                        `subscribe_${this.state.opts.subscription_id}`,
                        { subscription_id: this.state.opts.subscription_id }
                    );
                }

                if (this.props.group.data.ga_metrica) {
                    ReactGA.event({
                        category: "Senler",
                        action: "Subscribe",
                        label: this.state.opts.subscription_id,
                    });

                    ReactGA.event({
                        category: "Button",
                        action: "Click",
                        label: "Subscribe",
                    });

                    ReactGA.event({
                        category: "Button",
                        action: "Click",
                        label: `Subscribe_${this.state.opts.subscription_id}`,
                    });
                }

                if (this.props.group.data.fb_pixel) {
                    ReactPixel.track("Subscribe");
                    ReactPixel.trackCustom(
                        `Subscribe_${this.state.opts.subscription_id}`
                    );
                }

                if (this.props.group.data.google_tag) {
                    const utms = UrlParams.getUtms();

                    Analytics.push({
                        event: "Subscribe",
                        vk_user_id: UrlParams.params.vk_user_id,
                        ...utms,
                    });
                    Analytics.push({
                        event: `Subscribe_${this.state.opts.subscription_id}`,
                        vk_user_id: UrlParams.params.vk_user_id,
                        ...utms,
                    });
                }

                const vkPixel = this.props.group.data.vk_pixel;

                if (vkPixel) {
                    setTimeout(() => {
                        VkPixelCommon.event(vkPixel, "Subscribe");
                    }, 50);

                    setTimeout(() => {
                        VkPixelCommon.event(
                            vkPixel,
                            `Subscribe_${this.state.opts.subscription_id}`
                        );
                    }, 100);

                    let leadConversionValue = null;

                    // Если ценность цели подписки задана вручную - всегда передаем ее
                    if (this.props.opts.vk_pixel_lead_value) {
                        leadConversionValue = parseInt(
                            this.props.opts.vk_pixel_lead_value
                        );
                        // Если платная подписка и ценность цели не задана вручную, передадим стоимость подписки
                    } else if (this.props.opts.pay_price) {
                        leadConversionValue = parseInt(
                            this.props.opts.pay_price
                        );
                    }

                    setTimeout(() => {
                        VkPixelCommon.goal(vkPixel, "lead", leadConversionValue);
                    }, 150);
                }

                if (window.ym && typeof window.ym === "function") {
                    window.ym(
                        57544282,
                        "reachGoal",
                        "subscribe",
                        UrlParams.params
                    );
                }

                //@ts-ignore
                bridge.send('VKWebAppTrackEvent', {
                    event_name: 'subscribe',
                    user_id: UrlParams.params.vk_user_id
                })
            } catch (e) {
                this.logger.error(
                    { code: 9054, message: e.message },
                    "metric_error",
                    "Subscription.jsx"
                );
                Sentry.captureException(e);
                console.log(e);
            }
        } else {
            if (
                result.error_code &&
                parseInt(result.error_code) === 7 &&
                this.count_check_order < 5
            ) {
                this.count_check_order++;
                setTimeout(() => {
                    this.subscribe(group_pay_id);
                }, 2000);
            } else {
                this.props.mainPopout.error(result.message);
                DomHelpers.scrollToModal();
                this.logger.error(
                    { code: 9055, message: result.message },
                    "subsribe_error",
                    "Subscription.jsx"
                );
            }
        }
    }

    async setLeadPersonalInfo(params, phoneSending, emailSending) {
        try {
            const vars: PersonalCardType[] = [];

            if (phoneSending) {
                vars.push("phone");
            }

            if (emailSending) {
                vars.push("email");
            }

            const resp = await variablesService.getLeadVariablesByNames({
                params,
                vars,
            });

            const hasPhone = phoneSending ? resp["vars"]["phone"] : true;
            const hasEmail = emailSending ? resp["vars"]["email"] : true;

            if (resp.success && (!hasPhone || !hasEmail)) {
                const varsToSet = [];

                if (!hasPhone) {
                    if (bridge.supports("VKWebAppGetPhoneNumber")) {
                        try {
                            const bridgeResponse = await bridge.send(
                                "VKWebAppGetPhoneNumber"
                            );

                            if (bridgeResponse && bridgeResponse.phone_number) {
                                varsToSet.push({
                                    n: "phone",
                                    v: bridgeResponse.phone_number,
                                });
                            }
                        } catch (error) {}
                    }
                }

                if (!hasEmail) {
                    if (bridge.supports("VKWebAppGetEmail")) {
                        try {
                            const bridgeResponse = await bridge.send(
                                "VKWebAppGetEmail"
                            );

                            if (bridgeResponse && bridgeResponse.email) {
                                varsToSet.push({
                                    n: "email",
                                    v: bridgeResponse.email,
                                });
                            }
                        } catch (error) {}
                    }
                }

                if (varsToSet.length > 0) {
                    await variablesService.setLeadVariables({
                        params,
                        vars: varsToSet,
                    });
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    prepare_unsubscribe() {
        if (this.props.opts.pay)
            this.props.mainPopout.open(
                <Alert
                    actions={[
                        { title: "Отмена", autoClose: true, mode: "cancel" },
                        {
                            title: "Отпиcаться",
                            autoClose: false,
                            mode: "destructive",
                            action: () => {
                                this.props.mainPopout.close();
                                this.unsubscribe();
                            },
                        },
                    ]}
                    onClose={() => this.props.mainPopout.close()}
                >
                    <h2>Вы действительно хотите отписаться?</h2>
                    <p>Ваша оплата будет анулирована</p>
                </Alert>,
                "alert",
                () => {
                    DomHelpers.scrollToModal();
                }
            );
        else this.unsubscribe();
    }

    unsubscribe() {
        /**
         * @TODO - после подписки изменено открытие ссылок на внешние ресурсы
         */

        const { UrlParams, opts } = this.props;

        const notification_unsubscribe_text =
            opts.notification_unsubscribe_text;
        const notification_unsubscribe_button =
            opts.notification_unsubscribe_button;

        this.props.mainPopout.loading();
        _Subscribes.unSubscribe(
            {
                vk_group_id: this.props.group.vk_group_id,
                vk_user_id: this.props.user.id,
                subscription_id: this.state.opts.subscription_id,
                hash: this.props.hash,
                params: { ...this.props.UrlParams.params, source: "page" },
            },
            (result) => {
                if (result.success) {
                    this.start_pay = false;
                    this.props.mainPopout.close();
                    this.props.opts.subscribed = false;
                    this.props.opts.paid = false;
                    this.props.opts.count = this.props.opts.count - 1;
                    this.setState({
                        loading: false,
                        success: true,
                        opts: this.props.opts,
                    });

                    let title, desc, btn, href;
                    title = "Вы отписаны.";
                    desc = notification_unsubscribe_text
                        ? notification_unsubscribe_text
                        : "Рассылка Вам больше не будет приходить.";
                    if (result.url_after_unsubscribing) {
                        href = result.url_after_unsubscribing;

                        if (
                            href.includes("%25ref%25") ||
                            href.includes("%ref%")
                        ) {
                            href = this.changeRefVariable(
                                href,
                                UrlParams.getUtms()
                            );
                        }

                        if (
                            result.disabled_notify === 1 &&
                            !this.isMobileApp()
                        ) {
                            window.top.location.href = href;

                            if (
                                isVkSubscriptionUrl(
                                    result.url_after_unsubscribing
                                )
                            ) {
                                let subsriptionId = parseSubscriptionIdFromUrl(
                                    result.url_after_unsubscribing
                                );
                                window.location.hash = `s=${subsriptionId}`;
                                window.location.reload();
                            }

                            if (isVkPageUrl(href)) {
                                let pageId = parsePageIdFromUrl(href);

                                window.location.hash = `page=${pageId}`;
                                window.location.reload();
                                return;
                            }
                        }
                        btn = notification_unsubscribe_button
                            ? notification_unsubscribe_button
                            : "Продолжить";
                    }

                    const actions = [];

                    if (!result.url_after_unsubscribing) {
                        actions.push({
                            title: "ОK",
                            autoClose: true,
                            mode: "cancel",
                        });
                    }

                    if (
                        !result.disabled_notify ||
                        (result.url_after_unsubscribing && this.isMobileApp())
                    ) {
                        this.props.mainPopout.open(
                            <AlertSubscriptionSuccess
                                type="cancel"
                                actions={actions}
                                onClose={() => {
                                    this.props.mainPopout.close();
                                }}
                                title={title}
                                desc={getDecodedWithSpecialCharsString(desc)}
                                body={
                                    <>
                                        {btn ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <Button
                                                    onClick={() => {
                                                        window.top.location.href =
                                                            href;
                                                        if (
                                                            isVkSubscriptionUrl(
                                                                result.url_after_unsubscribing
                                                            )
                                                        ) {
                                                            let subsriptionId =
                                                                parseSubscriptionIdFromUrl(
                                                                    result.url_after_unsubscribing
                                                                );
                                                            window.location.hash = `s=${subsriptionId}`;
                                                            window.location.reload();
                                                        }

                                                        if (isVkPageUrl(href)) {
                                                            let pageId = parsePageIdFromUrl(href);

                                                            window.location.hash = `page=${pageId}`;
                                                            window.location.reload();
                                                        }
                                                    }}
                                                    size="l"
                                                    stretched
                                                >
                                                    {getDecodedWithSpecialCharsString(
                                                        btn
                                                    )}
                                                </Button>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </>
                                }
                            />,
                            "alert",
                            () => {
                                DomHelpers.scrollToModal();
                            }
                        );
                    }

                    try {
                        if (this.props.group.data.ya_metrica) {
                            ym("reachGoal", "unsubscribe", {
                                subscription_id:
                                    this.state.opts.subscription_id,
                            });
                            ym(
                                "reachGoal",
                                `unsubscribe_${this.state.opts.subscription_id}`,
                                {
                                    subscription_id:
                                        this.state.opts.subscription_id,
                                }
                            );
                        }

                        if (this.props.group.data.ga_metrica) {
                            ReactGA.event({
                                category: "Senler",
                                action: "Unsubscribe",
                                label: this.state.opts.subscription_id,
                            });

                            ReactGA.event({
                                category: "Button",
                                action: "Click",
                                label: "Unsubscribe",
                            });

                            ReactGA.event({
                                category: "Button",
                                action: "Click",
                                label: `Unsubscribe_${this.state.opts.subscription_id}`,
                            });
                        }

                        if (this.props.group.data.fb_pixel) {
                            ReactPixel.trackCustom("UnSubscribe");
                            ReactPixel.trackCustom(
                                `UnSubscribe_${this.state.opts.subscription_id}`
                            );
                        }

                        if (this.props.group.data.google_tag) {
                            const utms = UrlParams.getUtms();

                            Analytics.push({
                                event: "UnSubscribe",
                                vk_user_id: UrlParams.params.vk_user_id,
                                ...utms,
                            });
                            Analytics.push({
                                event: `UnSubscribe_${this.state.opts.subscription_id}`,
                                vk_user_id: UrlParams.params.vk_user_id,
                                ...utms,
                            });
                        }

                        const vkPixel = this.props.group.data.vk_pixel;

                        if (vkPixel) {
                            setTimeout(() => {
                                VkPixelCommon.event(vkPixel, "UnSubscribe");
                            }, 50);

                            setTimeout(() => {
                                VkPixelCommon.event(
                                    vkPixel,
                                    `UnSubscribe_${this.state.opts.subscription_id}`
                                );
                            }, 100);
                        }

                        if (window.ym && typeof window.ym === "function") {
                            window.ym(
                                57544282,
                                "reachGoal",
                                "unsubscribe",
                                UrlParams.params
                            );
                        }

                        //@ts-ignore
                        bridge.send('VKWebAppTrackEvent', {
                            event_name: 'unsubscribe',
                            user_id: UrlParams.params.vk_user_id
                        })
                    } catch (e) {
                        console.log(e);
                    }
                } else {
                    this.props.mainPopout.error(result.message);
                    DomHelpers.scrollToModal();
                }
            }
        );
    }

    showNotAllowedMessagesError() {
        const { mainPopout, UrlParams } = this.props;

        let url = `https://vk.com/app${UrlParams.gets.vk_app_id}_-${UrlParams.gets.vk_group_id}`;
        if (UrlParams.raw_hash) {
            url += UrlParams.raw_hash;
        }

        const content = (
            <>
                <div>Не поддерживается разрешение сообщений от сообщества</div>
                <br />
                <div>
                    <a
                        className="Tappable Button Button--sz-xl Button--lvl-primary Button--aln-center Tappable--inactive"
                        style={{
                            display: "block",
                            padding: "12px 16px",
                            borderRadius: 6,
                            textAlign: "center",
                        }}
                        href={`${url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Открыть в новой вкладке
                    </a>
                </div>
            </>
        );

        mainPopout.error(content, "Ошибка", []);
        DomHelpers.scrollToModal();
    }

    render() {
        const { opts } = this.props;

        /**
         * @TODO - Обсудить и утвердить логику оборажения кнопок для подписки\отписки
         * - Нужно ли ихделать ровно так же, как реализовано в каталоге рассылок
         */

        return (
            <>
                <>
                    {opts.subscribed
                        ? this.renderSubscribedButtons()
                        : this.renderNotSubscribedButtons()}
                </>
            </>
        );
    }

    renderNotSubscribedButtons() {
        const {
            text,
            opts,
            mainPopout,
            isPreview,
            color,
            textColor,
            isDisabledTimerBtn = false,
        } = this.props;
        const { isPending } = this.state;

        const hasData = Object.keys(opts).length > 0;
        const active = hasData && !isPending && !isPreview;

        let onClick = () => {};

        // Если еще не началась
        if (opts.limit_period_from) {
            onClick = () => {
                mainPopout.error(
                    <div>
                        Подписка на рассылку будет доступна{" "}
                        <b>{opts.limit_period_from}</b>
                    </div>,
                    "Информация"
                );

                DomHelpers.scrollToModal();
            };
            // Если уже прошла
        } else if (opts.limit_period_to) {
            onClick = () => {
                mainPopout.error(
                    <div>
                        Подписка на рассылку завершилась{" "}
                        <b>{opts.limit_period_to}</b>
                    </div>,
                    "Информация"
                );

                DomHelpers.scrollToModal();
            };

            // Если платная одноразовая
        } else if (opts.pay && opts.pay_price && !opts.pay_period) {
            onClick = () => {
                mainPopout.error(
                    <div>
                        Оформить платную подписку? <br />
                        Стоимость: <b>{opts.pay_price} ₽</b>
                    </div>,
                    "Информация",
                    [
                        {
                            title: "Продолжить",
                            autoClose: true,
                            mode: "default",
                            action: () => {
                                this.prepare_subscribe();

                                if (bridge.supports("VKWebAppResizeWindow")) {
                                    bridge.send("VKWebAppResizeWindow", {
                                        height: 655,
                                        width: 680,
                                    });
                                }

                                if (bridge.supports("VKWebAppScroll")) {
                                    bridge.send("VKWebAppScroll", {
                                        top: 0,
                                        speed: 400,
                                    });
                                }
                            },
                        },
                    ]
                );

                DomHelpers.scrollToModal();
            };

            // Если платная периодическая
        } else if (opts.pay && opts.pay_price && opts.pay_period) {
            onClick = () => {
                this.prepare_subscribe();
                // mainPopout.error(
                //     <div>
                //         Оформить платную периодическую рассылку? <br/>
                //         Стоимость: <b>{opts.pay_price} ₽</b> {getPayPeriod(opts)}
                //     </div>
                // ,'Информация', [
                //     {title: 'Продолжить', autoClose: true, mode: 'default', action: () => {
                //         this.prepare_subscribe()
                //     }}
                // ])
            };
        } else {
            onClick = () => {
                this.prepare_subscribe();
            };
        }

        return (
            <Button
                className={`PageSubscribeButton ${isDisabledTimerBtn ? "inactive" : ""}`}
                size="l"
                style={{
                    backgroundColor: isDisabledTimerBtn
                        ? "var(--vkui--color_background_secondary_alpha)"
                        : color,
                    color: isDisabledTimerBtn
                        ? "var(--vkui--color_icon_primary)"
                        : textColor,
                }}
                onClick={
                    active
                        ? () => {
                              onClick();
                          }
                        : () => {}
                }
            >
                <div className="B-Cover__button-inner">
                    <span className="B-Cover__button-text">{text}</span>
                    <span className="B-Cover__button-icon">
                        <Icon28ChevronRightOutline width={18} />
                    </span>
                </div>
            </Button>
        );
    }

    renderSubscribedButtons() {
        const {
            opts,
            mainPopout,
            isPreview,
            color,
            textColor,
            unsubscribeText
        } = this.props;
        const { isPending } = this.state;

        const hasData = Object.keys(opts).length > 0;
        const active = hasData && !isPending && !isPreview;

        let onClick = () => {
            this.unsubscribe && this.unsubscribe();
        };

        let btn_text = unsubscribeText ? unsubscribeText : "Отписаться";
        let inactive = true;

        if (opts.pay && opts.pay_period > 0 && opts.status === "start") {
            btn_text = "Продлить подписку";
            inactive = false;
            onClick = () => {
                mainPopout.error(
                    <div>
                        Продлить платную рассылку?
                        <br />
                        Стоимость: <b>{opts.pay_price} ₽</b>
                    </div>,
                    "Информация",
                    [
                        {
                            title: "Продлить",
                            autoClose: true,
                            mode: "default",
                            action: () => {
                                this.prepare_subscribe();
                            },
                        },
                        {
                            title: "Отмена",
                            autoClose: true,
                            mode: "default",
                            action: () => {},
                        },
                    ]
                );

                DomHelpers.scrollToModal();
            };
        } else if (opts.pay && opts.paid && !opts.pay_period) {
        }

        return (
            <Button
                className={`PageSubscribeButton ${inactive ? "inactive" : ""}`}
                size="l"
                mode={inactive ? "secondary" : "primary"}
                style={{
                    backgroundColor: !inactive
                        ? color
                        : "var(--vkui--color_background_secondary_alpha)",
                    color: !inactive
                        ? textColor
                        : "var(--vkui--color_icon_primary)",
                }}
                onClick={active ? onClick : () => {}}
            >
                <div className="B-Cover__button-inner">
                    <span className="B-Cover__button-text">
                        {btn_text}
                    </span>
                    {!inactive && (
                        <span className="B-Cover__button-icon">
                            <Icon28ChevronRightOutline width={18} />
                        </span>
                    )}
                </div>
            </Button>
        );
    }

    renderRedirectButton(href, btn, openInNewTab = false) {
        const { UrlParams } = this.props;

        if (UrlParams.gets.vk_platform === "desktop_web") {
            return (
                <Button
                    onClick={() => {
                        if (openInNewTab) {
                            window.open(href)
                        } else {
                            let subsriptionId = parseSubscriptionIdFromUrl(href);
                            window.location.hash = `s=${subsriptionId}`;
                            window.location.reload();
                        }
                    }}
                    size="l"
                    stretched
                >
                    {getDecodedWithSpecialCharsString(btn)}
                </Button>
            );
        } else {
            if (openInNewTab) {
                return (
                    <Button
                        href={href}
                        target="_blank"
                        mode="primary"
                        size="l"
                        stretched
                    >
                        {btn}
                    </Button>
                );
            } else {
                return (
                    <Button
                        onClick={() => {
                            let subsriptionId = parseSubscriptionIdFromUrl(href);
                            window.location.hash = `s=${subsriptionId}`;
                            window.location.reload();
                        }}
                        size="l"
                        stretched
                    >
                        {getDecodedWithSpecialCharsString(btn)}
                    </Button>
                );
            }
        }
    }

    renderRedirectUrl(href, btn) {
        const { UrlParams } = this.props;

        if (UrlParams.gets.vk_platform === "desktop_web") {
            return (
                <Button
                    onClick={() => {
                        window.top.location.href = href;

                        if (isVkPageUrl(href)) {
                            let pageId = parsePageIdFromUrl(href);

                            window.location.hash = `page=${pageId}`;
                            window.location.reload();
                        }
                    }}
                    size="l"
                    stretched
                >
                    {getDecodedWithSpecialCharsString(btn)}
                </Button>
            );
        }

        return (
            <Button
                href={href}
                target="_blank"
                mode="primary"
                size="l"
                stretched
            >
                {btn}
            </Button>
        );
    }

    showSubscriptionSuccessAlert(params) {
        const { mainPopout, opts } = this.props;

        const notification_subscribe_text = opts.notification_subscribe_text
            ? opts.notification_subscribe_text
            : params.desc;

        const notification_subscribe_button =
            opts.notification_subscribe_button;

        mainPopout.open(
            <AlertSubscriptionSuccess
                type="success"
                actions={params.actions}
                onClose={() => {
                    mainPopout.close();
                }}
                title={params.title}
                desc={getDecodedWithSpecialCharsString(
                    notification_subscribe_text
                )}
                body={
                    <>
                        {params.btn ? (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "column",
                                }}
                            >
                                {isVkSubscriptionUrl(params.href)
                                    ? this.renderRedirectButton(
                                        params.href,
                                        notification_subscribe_button
                                            ? notification_subscribe_button
                                            : params.btn,
                                        params.openInNewTab
                                      )
                                    : this.renderRedirectUrl(
                                        params.href,
                                        notification_subscribe_button
                                            ? notification_subscribe_button
                                            : params.btn
                                      )}
                            </div>
                        ) : (
                            ""
                        )}
                    </>
                }
            />,
            "alert",
            () => {
                DomHelpers.scrollToModal();

                if (params.href) {
                    document
                        .querySelector(".vkuiView")
                        .classList.add("disabled-overlay");
                }
            }
        );
    }

    isMobileApp() {
        let platform = this.props.UrlParams.params.vk_platform;
        let isMobileApp =
            ["mobile_android", "mobile_iphone"].indexOf(platform) >= 0;

        return isMobileApp;
    }
}

export default SubscriptionButton;
