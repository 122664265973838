import React from "react";
import { List, Cell, Separator, CellButton } from "@vkontakte/vkui";
import Banner from "../Elements/Banner";
import { simpleTextToHtml } from "../../includes/Helpers/TextToHtml";
import { Icon24Camera } from "@vkontakte/icons";

declare type GroupSettingsNavigatorProps = {
    viewType: any;
    groupData: any;
    navigateView: Function;
    navigateBanners: Function;
    navigateText: Function;
    platform: string;
};

const GroupSettingsNavigator = (props: GroupSettingsNavigatorProps) => {
    const {
        navigateView,
        navigateBanners,
        navigateText,
        viewType,
        groupData,
        platform,
    } = props;

    const handleNavigateView = (event) => {
        navigateView && navigateView();
    };

    const handleNavigateBanners = (event) => {
        navigateBanners && navigateBanners();
    };

    const handleNavigateText = (event) => {
        navigateText && navigateText();
    };

    const isSingle = viewType.value === "single";
    const hasDesktopBanner =
        ["img", "youtube", "vimeo", "vk_video"].indexOf(
            groupData.banner_type
        ) >= 0 &&
        (groupData.banner_url || groupData.video_key);
    const hasMobileBanner =
        ["img", "youtube", "vimeo", "vk_video"].indexOf(
            groupData.mobile_banner_type
        ) >= 0 &&
        (groupData.mobile_banner_url || groupData.mobile_video_key);

    const resolveBanner = () => {
        if (platform === "desktop_web") {
            if (hasDesktopBanner) {
                return (
                    <div
                        className="wrapper_clickable"
                        onClick={handleNavigateBanners}
                        style={{ marginTop: 10 }}
                    >
                        <Banner
                            platform={"desktop_web"}
                            video_autoplay={0}
                            video_key={groupData.video_key}
                            mobile_video_key={groupData.mobile_video_key}
                            type="catalog"
                            banner_type={groupData.banner_type}
                            mobile_banner_type={groupData.mobile_banner_type}
                            banner_url={groupData.banner_url}
                            mobile_banner_url={groupData.mobile_banner_url}
                        />
                    </div>
                );
            } else {
                return (
                    <>
                        <Separator style={{ marginTop: 10 }} />
                        <CellButton
                            style={{
                                marginTop: 6,
                                marginBottom: 6,
                                userSelect: "none",
                            }}
                            onClick={handleNavigateBanners}
                            before={<Icon24Camera style={{ paddingRight: 10 }} />}
                            centered
                        >
                            Загрузить баннер
                        </CellButton>
                        <Separator />
                    </>
                );
            }
        } else {
            if (hasMobileBanner) {
                return (
                    <div
                        className="wrapper_clickable"
                        onClick={handleNavigateBanners}
                        style={{ marginTop: 10 }}
                    >
                        <Banner
                            platform={"mobile"}
                            video_autoplay={0}
                            video_key={groupData.video_key}
                            mobile_video_key={groupData.mobile_video_key}
                            type="catalog"
                            banner_type={groupData.banner_type}
                            mobile_banner_type={groupData.mobile_banner_type}
                            banner_url={groupData.banner_url}
                            mobile_banner_url={groupData.mobile_banner_url}
                        />
                    </div>
                );
            } else {
                return (
                    <>
                        <Separator style={{ marginTop: 10 }} />
                        <CellButton
                            style={{
                                marginTop: 10,
                                marginBottom: 10,
                                userSelect: "none",
                            }}
                            onClick={handleNavigateBanners}
                            before={<Icon24Camera style={{ paddingRight: 10 }} />}
                            centered
                        >
                            Загрузить баннер
                        </CellButton>
                        <Separator />
                    </>
                );
            }
        }
    };

    return (
        <div className="content--inner">
            <div className="GroupSettingsNavigator">
                <List style={{ paddingTop: 10 }}>
                    <Cell
                        onClick={handleNavigateView}
                        indicator={viewType.name}
                        expandable
                    >
                        Вид
                    </Cell>
                </List>

                {!isSingle && (
                    <>
                        {resolveBanner()}
                        <div
                            className="GroupSettingsNavigator__text"
                            onClick={handleNavigateText}
                        >
                            <div>
                                {simpleTextToHtml(groupData.app_text)}
                            </div>
                            <span>
                                {groupData.app_text
                                    ? "Редактировать"
                                    : "Редактировать описание"}
                            </span>
                        </div>
                        <Separator />
                    </>
                )}
            </div>
        </div>
    );
};

export default GroupSettingsNavigator;
