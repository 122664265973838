import React, { Component } from "react";
import {
    Button,
    Div,
    UsersStack,
    platform,
    ActionSheet,
    ActionSheetItem,
    Platform,
    ActionSheetDefaultIosCloseItem,
} from "@vkontakte/vkui";
import bridge from "@vkontakte/vk-bridge";
import * as Sentry from "@sentry/react";

import Banner from "./Elements/Banner";
import { getPayPeriod } from "../includes/Helpers/HelpersSubscriptions";
import {
    getDaysCount,
    daysBetween,
    dateToISOFormat,
    isIOS,
} from "../includes/Helpers/Helpers";
import TextToHtml from "../includes/Helpers/TextToHtml";
import container from "../container";

import ym from "react-yandex-metrika";
import VkPixelCommon from "../includes/Metrics/VkPixelCommon";
import Logger from "../includes/Services/Logger";
import { Icon20RecentOutline, Icon24MoreHorizontal, Icon24Write } from "@vkontakte/icons";

type SubscriptionFullProps = {
    groupData: any;
    opts: any;
    urlParams: any;
    mainPopout: any;
    unsubscribe: any;
    setHeight: any;
    prepare_subscribe: any;
    mainRef: any;
};

type SubscriptionFullState = {
    windowWidth: any;
    windowHeight: any;
};

class SubscriptionFull extends Component<
    SubscriptionFullProps,
    SubscriptionFullState
> {
    bannerWrapper: any;
    popoutRef: any;
    logger: Logger;

    static defaultProps = {
        opts: {},
    };

    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
        };

        this.bannerWrapper = React.createRef();

        this.logger = container.get("logger");
        this.popoutRef = React.createRef()
    }

    handleResize = () => {
        this.setState({
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
        });
    };

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);

        const { urlParams } = this.props;

        const isSubscriptionPage = !!urlParams.params.s;

        try {
            if (this.props.groupData && this.props.groupData.ya_metrica) {
                ym("reachGoal", "view", {
                    subscription_id: this.props.opts.subscription_id,
                });
                ym("reachGoal", `view_${this.props.opts.subscription_id}`, {
                    subscription_id: this.props.opts.subscription_id,
                });
            }

            if (
                this.props.groupData &&
                this.props.groupData.vk_pixel &&
                false === isSubscriptionPage
            ) {
                const vkPixel = this.props.groupData.vk_pixel
                // Если переход из каталога - отправим событие в пиксель о просмотре страницы
                setTimeout(() => {
                    VkPixelCommon.event(vkPixel, "View");
                }, 50);

                setTimeout(() => {
                    VkPixelCommon.event(vkPixel, `View_${this.props.opts.subscription_id}`);
                }, 100);
            }
        } catch (error) {
            this.logger.error(
                { code: 9048, message: error.message },
                "runtime_error",
                "Subscriptions.jsx"
            );
            Sentry.captureException(error);
            console.log(error);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    getDaysCount(daysLeft) {
        return getDaysCount(daysLeft);
    }

    getDaysCountNumber() {
        try {
            const { opts } = this.props;

            if (!opts.pay_expired) {
                return "";
            }

            const date = dateToISOFormat(opts.pay_expired);
            if (!date) {
                return "";
            }

            const now = new Date();
            const endDate = new Date(date);

            const daysCount = daysBetween(now, endDate);

            return daysCount;
        } catch (e) {
            this.logger.error(
                { code: 9049, message: e.message },
                "runtime_error",
                "SubscriptionFull.jsx"
            );
            Sentry.captureException(e);
            return 0;
        }
    }

    getMinHeight() {
        /**
         * @TODO - как высчитать высоту для IOS
         */
        return "100vh";
    }

    showCancelContext = () => {
        const { mainPopout, unsubscribe, opts } = this.props;
        mainPopout.open(
            <ActionSheet
                onClose={() => mainPopout.close()}
                iosCloseItem={<ActionSheetDefaultIosCloseItem />}
                toggleRef={this.popoutRef}
            >
                <ActionSheetItem
                    mode="destructive"
                    onClick={() => {
                        unsubscribe && unsubscribe();
                    }}
                    autoClose
                >
                    {opts.btn_cancel}
                </ActionSheetItem>
                {platform() !== Platform.IOS && (
                    <ActionSheetItem
                        className={
                            platform() === Platform.ANDROID
                                ? "action-sheet-android-cancel"
                                : ""
                        }
                        mode={"default"}
                        autoClose
                    >
                        Отмена
                    </ActionSheetItem>
                )}
            </ActionSheet>
        );
    };

    imgStartRender = () => {
        requestAnimationFrame(this.imgEndRender);
    };

    imgEndRender = () => {
        const { setHeight } = this.props;
        const wrapper: any = document.querySelector("#full-banner-wrapper");
        if (wrapper) {
            const img: any = wrapper.querySelector("#full-img");
            if (img) {
                const imgHeight = img.offsetHeight;
                wrapper.style.height = `${imgHeight}px`;

                const contentInner: any =
                    document.querySelector(".content--inner");

                if (contentInner) {
                    const realContentHeight = contentInner.offsetHeight;
                    const resultAppHeight = Math.min(
                        realContentHeight + 140,
                        8050
                    );
                    bridge.send("VKWebAppResizeWindow", {
                        height: resultAppHeight,
                        width: 680,
                    });
                    setHeight && setHeight();
                }
            }
        }
    };

    renderBanner() {
        const { opts, setHeight } = this.props;

        return (
            <Banner
                type="page"
                banner_type={opts.banner_type}
                mobile_banner_type={opts.mobile_banner_type}
                banner_url={opts.banner_url}
                mobile_banner_url={opts.mobile_banner_url}
                video_key={opts.video_key}
                mobile_video_key={opts.mobile_video_key}
                video_autoplay={opts.video_autoplay}
                name={opts.name}
                platform={this.props.urlParams.params.vk_plaform}
                iFrameOnLoad={(e) => {
                    setHeight && setHeight();
                }}
                imgOnLoad={(e) => {
                    const wrapper: any = document.querySelector(
                        "#full-banner-wrapper"
                    );
                    try {
                        if (
                            this.state.windowWidth &&
                            e.currentTarget.naturalWidth > 0 &&
                            e.currentTarget.naturalHeight > 0
                        ) {
                            let ratio =
                                this.state.windowWidth /
                                e.currentTarget.naturalWidth;

                            let imgHeight =
                                e.currentTarget.naturalHeight * ratio;
                            if (wrapper) {
                                wrapper.style.height = `${imgHeight}px`;
                            }
                        }
                    } catch (e) {
                        console.log(e);
                    }

                    requestAnimationFrame(this.imgStartRender);
                }}
            />
        );
    }

    renderHeader() {
        const { opts, urlParams } = this.props;
        const daysLeft = this.getDaysCountNumber();
        const isAdmin = urlParams.params.vk_viewer_group_role === "admin";
        let text =
            daysLeft > 0
                ? `До конца подписки ${this.getDaysCount(daysLeft)}`
                : "";

        return (
            <div className="SubscriptionFull__header">
                <div className="SubscriptionFull__header-name">
                    {opts.name}
                </div>

                {opts.pay_expired && daysLeft > 0 && (
                    <div className="SubscriptionFull__header-expire">
                        <Icon20RecentOutline width={13} height={13} />
                        <span>{text}</span>
                    </div>
                )}
            </div>
        );
    }

    renderDescription() {
        const { opts } = this.props;

        return (
            <div className="SubscriptionFull__text">
                <div className="SubscriptionFull__text-inner">
                    {TextToHtml(opts.text)}
                </div>
            </div>
        );
    }

    renderSubscribers() {
        const { opts } = this.props;

        return (
            <div className="SubscriptionFull__subscribers">
                <UsersStack photos={opts.avatars}>
                    Подписчиков: {opts.count}
                </UsersStack>
            </div>
        );
    }

    renderSubscribedButtons() {
        const { opts, prepare_subscribe, unsubscribe } = this.props;

        return (
            <>
                {opts.pay && opts.pay_period > 0 && opts.status === "start" ? (
                    <div
                        style={{
                            display: "flex",
                            margin: "0 auto",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            className="SubscriptionFull__buttons-item SubscriptionFull__button_prolong ButtonBuy ButtonBuy__prolong ButtonBuy_full"
                            size="l"
                            mode="outline"
                            before={
                                <div>
                                    <span>{opts.pay_price}</span>
                                    <span>₽</span>
                                </div>
                            }
                            onClick={() => {
                                prepare_subscribe && prepare_subscribe();
                            }}
                        >
                            Продлить
                        </Button>
                        <div ref={this.popoutRef}>
                            <Button
                                mode="tertiary"
                                className="SubscriptionFull__buttons-item"
                                align="center"
                                style={{
                                    width: 44,
                                    flexGrow: 0,
                                }}
                                onClick={this.showCancelContext}
                            >
                                <Icon24MoreHorizontal />
                            </Button>
                        </div>
                    </div>
                ) : (
                    <Button
                        className={[
                            "SubscriptionFull__buttons-item",
                            "SubscriptionFull__buttons-item_single",
                        ].join(" ")}
                        size="l"
                        mode="secondary"
                        onClick={() => {
                            unsubscribe && unsubscribe();
                        }}
                    >
                        {opts.btn_cancel}
                    </Button>
                )}
            </>
        );
    }

    renderDefaultButtons() {
        const { opts, prepare_subscribe } = this.props;
        let classNames = [
            "SubscriptionFull__buttons-item",
            "SubscriptionFull__buttons-item_single",
        ];

        if (opts.pay) {
            classNames = [...classNames, ...["ButtonBuy", "ButtonBuy_full"]];
        }

        switch (opts.status) {
            case "start":
                return (
                    <Button
                        className={classNames.join(" ")}
                        size="l"
                        before={opts.pay && this.renderPriceInfo()}
                        onClick={() => {
                            prepare_subscribe && prepare_subscribe();
                        }}
                    >
                        {opts.btn_ok}
                    </Button>
                );
            case "not_start":
                return (
                    <div className="SubscriptionFull__buttons-item text">
                        Начало {opts.limit_period_from}
                    </div>
                );
            case "finished":
                return (
                    <div className="SubscriptionFull__buttons-item text">
                        Завершено {opts.limit_period_to}
                    </div>
                );
            default:
                return "";
        }
    }

    renderPriceInfo() {
        const { opts } = this.props;

        return (
            <div className="SubscriptionFull__price">
                <span className="SubscriptionFull__price-cost">
                    {opts.pay_price} <i>₽</i>
                </span>
                <span className="SubscriptionFull__price-period">
                    {getPayPeriod(opts)}
                </span>
            </div>
        );
    }

    render() {
        try {
            const { opts } = this.props;

            const hasBanner = !!opts.banner_type;
            const hasDescription = !!opts.text;
            const hasSubscribers =
                opts.count > 0 && opts.hide_subscriptions === 0;
            const isSubscribed = opts.subscribed;
            const isPeriodicAndPaid =
                opts.pay && opts.pay_period > 0 && opts.status === "start";

            return (
                <>
                    <div style={{ minHeight: isIOS() ? "100vh" : 100 }}>
                        {hasBanner && this.renderBanner()}
                        <Div>
                            {this.renderHeader()}
                            {hasDescription && this.renderDescription()}
                            {hasSubscribers && this.renderSubscribers()}
                        </Div>
                        <Div>
                            <div
                                className={`SubscriptionFull__buttons ${
                                    isPeriodicAndPaid
                                        ? "SubscriptionFull__buttons_paid-periodic"
                                        : ""
                                }`}
                            >
                                {isSubscribed
                                    ? this.renderSubscribedButtons()
                                    : this.renderDefaultButtons()}
                            </div>
                        </Div>
                    </div>
                </>
            );
        } catch (e) {
            this.logger.error(
                { code: 9050, message: e.message },
                "render_error",
                "SubscriotionFull.jsx"
            );
            Sentry.captureException(e);
            return null;
        }
    }
}

export default SubscriptionFull;
