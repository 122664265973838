import React, { useState, useContext } from "react";
import { Button } from "@vkontakte/vkui";
import { BlockButtonData } from "../../includes/Structures";
import bridge from "@vkontakte/vk-bridge";
import container from "../../container";
import SubscriptionButton from "../SubscriptionButton";
import PageStatisticService from "../../includes/Services/PageStatisticService";
import ym from "react-yandex-metrika";
import ReactGA from "react-ga4";
import ReactPixel from "../../includes/Metrics/ReactPixel";
import VkPixelCommon from "../../includes/Metrics/VkPixelCommon";
import { Analytics } from "../../includes/Metrics/Analytics";
import AlertSubscriptionSuccess from "../Modals/Alert/AlertSubscriptionSuccess";

import { Context } from "../../context";
import { Icon28ChevronRightOutline } from "@vkontakte/icons";

type PageButtonProps = {
    data: BlockButtonData;
    blockId: string;
    pageId: string;
    isPreview: boolean;
    isDisabledTimerBtn?: boolean;
};

const PageButton = (props: PageButtonProps) => {
    const { data, blockId, pageId, isPreview, isDisabledTimerBtn = false } = props;

    const { location, popout, user, pages, groupSiteData } =
        useContext(Context);

    const LaunchParams = container.get("LaunchParams");
    const PagesService = container.get("PagesService");
    const BotService = container.get("BotService");

    const [isPending, setIsPending] = useState(false); // Занята ли кнопка в данный момент
    const [isDisabled, setIsDisabled] = useState(false);

    const sendLead = async (user_data) => {
        const request_data = {
            ...user_data,
            page_id: pageId,
        };

        if (data.lead_admin) {
            request_data.lead_admin = data.lead_admin;
        }

        popout.loading();

        const response = await PagesService.sendLead(request_data);

        popout.close();

        if (response.success) {
            popout.successAnimation("Ваша заявка отправлена");
        } else {
            if (
                response.error_code === 405 &&
                response.message === "Сообщество не найдено"
            ) {
                popout.errorAnimation(
                    "Необходимо подключить сообщество в личном кабинете senler.ru"
                );
            } else {
                popout.errorAnimation(response.message);
            }
        }
    };

    const sendLeadTrigger = async (user_lead_data) => {
        const request_data = {
            ...user_lead_data,
            page_id: pageId,
        };

        if (data.lead_admin) {
            request_data.lead_admin = data.lead_admin;
        }

        await PagesService.sendLeadTrigger(request_data);

        setIsPending(false);
    };

    const requestUserAllowMessagesFromGroup = async (user_lead_data) => {
        if (bridge.supports("VKWebAppAllowMessagesFromGroup")) {
            setIsPending(true);

            try {
                const resp = await bridge.send(
                    "VKWebAppAllowMessagesFromGroup",
                    {
                        group_id: LaunchParams.params.vk_group_id,
                        key: `${LaunchParams.params.vk_user_id}-${Date.now()}`,
                    }
                );

                // Если пользователь дал разрешение или оно выдано ранее
                if (true === resp.result) {
                    sendLeadTrigger(user_lead_data);
                }
            } catch (e) {
                /**
                 * Обработать неподдерживаемость ивента
                 */
                console.log("error", e);
            }
        } else {
            /**
             * @TODO - обработать отсутствие ивента
             */
        }
    };

    const saveGoal = (type: string) => {
        // В режиме предпросмотра не учитываем цели
        if (isPreview) return false;

        try {
            const groupData = groupSiteData.get();

            const goalData = {
                hit_id: pages.getHitId(),
                type: type,
                vk_group_id: LaunchParams.params.vk_group_id,
                vk_user_id: LaunchParams.params.vk_user_id,
                vk_user_role: LaunchParams.params.vk_viewer_group_role,
                vk_ref: LaunchParams.params.vk_ref,
                vk_platform: LaunchParams.params.vk_platform,
                button_id: data.id,
                block_id: blockId,
                page_id: pageId,
            };

            PageStatisticService.goal(goalData).then((res) => {});

            /**
             * Отправка целей в метрику
             */
            if (groupData.ya_metrica && typeof window.ym !== "undefined") {
                let params = {
                    page_id: pageId,
                } as any;

                if (type === "subscription") {
                    // Достижение цели для подписки на определенную группу подписчиков
                    params.subscription_id = data.subscription_id;
                    /**
                     * Подписка на любую группу подписок
                     */
                    ym("reachGoal", "page_subscribe", params);

                    /**
                     * Подписка на группу подписок с ID = N
                     */
                    ym(
                        "reachGoal",
                        `page_subscribe_${data.subscription_id}`,
                        params
                    );

                    /**
                     * Подписка на группу подписок с ID = N на странице с ID = M
                     */
                    ym(
                        "reachGoal",
                        `page_subscribe_${data.subscription_id}_${pageId}`,
                        params
                    );
                } else {
                    /**
                     * Выполнение целевого действия lead | join | bot
                     */
                    ym("reachGoal", `page_${type}`, params);

                    /**
                     * Выполнение целевого действия lead | join настранице с ID = N
                     */
                    ym("reachGoal", `page_${type}_${pageId}`, params);
                }

                if (false) {
                    // Достижение цели для кнопки
                    ym("reachGoal", `page_${type}_${data.id}`, params);
                }
            }

            /**
             * Отправка целей в гугл-аналитику
             */
            if (groupData.ga_metrica) {
                if (type === "subscription") {
                    ReactGA.event({
                        category: "Button",
                        action: "Click",
                        label: `page_subscribe`,
                    });

                    /**
                     * Достижение цели для подписки на определенную группу подписчиков на определеннной странице
                     */
                    ReactGA.event({
                        category: "Button",
                        action: "Click",
                        label: `page_subscribe_${data.subscription_id}`,
                    });

                    /**
                     * Достижение цели для подписки на определенную группу подписчиков
                     */
                    ReactGA.event({
                        category: "Button",
                        action: "Click",
                        label: `page_subscribe_${data.subscription_id}_${pageId}`,
                    });
                }

                if (
                    type === "lead" ||
                    type === "join_community" ||
                    type === "bot_add"
                ) {
                    /**
                     * Простое достижение цели по типу
                     */
                    ReactGA.event({
                        category: "Button",
                        action: "Click",
                        label: `page_${type}`,
                    });

                    /**
                     * Достижение цели по типу на конккретной странице
                     */
                    ReactGA.event({
                        category: "Button",
                        action: "Click",
                        label: `page_${type}_${pageId}`,
                    });
                }
            }

            const vkPixel = groupData.vk_pixel

            /**
             * Отправка целей в пиксель вк
             */
            if (groupData.vk_pixel) {
                /**
                 * Событие при подписке на рассылку
                 */
                if (type === "subscription") {
                    /**
                     * Подписка на любую группу подписок
                     */
                    setTimeout(() => {
                        VkPixelCommon.event(vkPixel, 'page_subscribe');
                    }, 50);

                    /**
                     * Подписка на конкретную группу подписок
                     */
                    setTimeout(() => {
                        VkPixelCommon.event(vkPixel,
                            `page_subscribe_${data.subscription_id}`
                        );
                    }, 100);

                    /**
                     * Подписка на конкретную группу подписок на странице pageId
                     */
                    setTimeout(() => {
                        VkPixelCommon.event(vkPixel,
                            `page_subscribe_${data.subscription_id}_${pageId}`
                        );
                    }, 150);

                    /**
                     * Достижение цели "Оформление подписки"
                     */
                    setTimeout(() => {
                        VkPixelCommon.goal(vkPixel, "subscribe");
                    }, 200);
                }

                /**
                 * Общее событие по типу lead | join_community
                 */
                if (
                    type === "lead" ||
                    type === "join_community" ||
                    type === "bot_add"
                ) {
                    setTimeout(() => {
                        VkPixelCommon.event(vkPixel, `page_${type}`);
                    }, 50);

                    setTimeout(() => {
                        VkPixelCommon.event(vkPixel, `page_${type}_${pageId}`);
                    }, 100);
                }

                if (type === "bot_add") {
                    /**
                     * Достижение цели "Добавление в бота"
                     */
                    setTimeout(() => {
                        VkPixelCommon.goal(vkPixel, "lead");
                    }, 150);
                }

                if (type === "lead") {
                    /**
                     * Достижение цели - "Получение потенциального клиента"
                     */
                    setTimeout(() => {
                        VkPixelCommon.goal(vkPixel, "lead");
                    }, 150);
                }
            }

            /**
             * Отправка целей в GoogleTagManager
             */
            if (groupData.google_tag) {
                // const utms = urlParams.getUtms();
                const utms = LaunchParams.getUtms();

                if (type === "subscription") {
                    Analytics.push({
                        event: `page_subscribe`,
                        vk_user_id: LaunchParams.params.vk_user_id,
                        ...utms,
                    });

                    Analytics.push({
                        event: `page_subscribe_${data.subscription_id}`,
                        vk_user_id: LaunchParams.params.vk_user_id,
                        ...utms,
                    });

                    Analytics.push({
                        event: `page_subscribe_${data.subscription_id}_${pageId}`,
                        vk_user_id: LaunchParams.params.vk_user_id,
                        ...utms,
                    });
                } else {
                    Analytics.push({
                        event: `page_${type}`,
                        vk_user_id: LaunchParams.params.vk_user_id,
                        ...utms,
                    });

                    Analytics.push({
                        event: `page_${type}_${pageId}`,
                        vk_user_id: LaunchParams.params.vk_user_id,
                        ...utms,
                    });
                }
            }

            /**
             * Отправка цели в FacebookPixel
             */
            if (groupData.fb_pixel) {
                if (type === "subscription") {
                    ReactPixel.trackCustom(`page_subscribe`);
                    ReactPixel.trackCustom(
                        `page_subscribe_${data.subscription_id}`
                    );
                    ReactPixel.trackCustom(
                        `page_subscribe_${data.subscription_id}_${pageId}`
                    );
                } else {
                    ReactPixel.trackCustom(`page_${type}`);
                    ReactPixel.trackCustom(`page_${type}_${pageId}`);
                }
            }
        } catch (e) {
            console.log(e);
            /**
             * @TODO log error
             */
        }
    };

    let action = () => {};

    if (data.action === "subscribe") {
        const subscriptions = pages.getLandingSubscriptions();
        const subscriptionData = subscriptions[data.subscription_id]
            ? subscriptions[data.subscription_id]
            : {};

        return (
            <SubscriptionButton
                isPreview={isPreview}
                color={data.color}
                textColor={data.text_color}
                mainPopout={popout}
                key={data.subscription_id}
                UrlParams={LaunchParams}
                user={user}
                opts={subscriptionData}
                hash={subscriptionData ? subscriptionData.hash : ""}
                setForceSubscriptionPage={location.setForceSubscriptionPage}
                isDisabledTimerBtn={isDisabledTimerBtn}
                text={data.text}
                unsubscribeText={data.unsubscribe_text}
                onSuccess={() => {
                    saveGoal("subscription");
                }}
                group={{
                    vk_group_id: LaunchParams.params.vk_group_id,
                    data: groupSiteData.get(),
                }}
            />
        );
    }

    if (isPreview) {
        // В режиме предпросмотра не обрабатываем нажатие
    } else if (data.action === "lead") {
        /**
         * Обработка события - Заявка с номером телефона
         */
        action = async () => {
            /**
             * Запросим номер телефона
             */
            if (bridge.supports("VKWebAppGetPhoneNumber")) {
                setIsPending(true);

                try {
                    const res = await bridge.send("VKWebAppGetPhoneNumber");

                    if (res && res.phone_number) {
                        sendLead(res);
                        saveGoal("lead");

                        /**
                         * Если стоит галочка "Отправить событие в триггер"
                         * - Запросим разрешение на отправку сообщений от сообщества
                         * - Отправим событие в триггер
                         */
                        if (true === data.send_trigger) {
                            requestUserAllowMessagesFromGroup(res);
                        }
                    }

                    setIsPending(false);
                } catch (e) {
                    /**
                     * @TODO - обработать ошибку - в частности Отказ\Отмену
                     */
                    setIsPending(false);
                }
            } else {
                /**
                 * @TODO - Обработать отсутствие ивента
                 */
            }
        };
    } else if (data.action === "join_community") {
        action = async () => {
            /**
             * В идеале - проверить является ли пользователь подписчиком сообщества
             * vk_viewer_group_role - не совсем подходит, так как администратор может быть не подписан - для тестирования
             * Нужно проверять через апи - groups.isMember - но для этого нужен токен пользователя без scope
             */

            if (bridge.supports("VKWebAppJoinGroup")) {
                setIsPending(true);

                try {
                    const resp = await bridge.send("VKWebAppJoinGroup", {
                        group_id: LaunchParams.params.vk_group_id,
                    });
                    setIsPending(false);

                    if (resp.result === true) {
                        popout.successAnimation("Вы подписаны");
                        saveGoal("join_community");
                    }
                } catch (e) {
                    console.log(e);
                    /**
                     * @TODO - обработать ошибку - в частности Отказ\Отмену
                     */
                    setIsPending(false);
                }
            } else {
                /**
                 * @TODO - Обработать отсутствие ивента
                 */
            }
        };
    } else if (data.action === "bot") {
        action = async () => {
            if (bridge.supports("VKWebAppAllowMessagesFromGroup")) {
                const resp = await bridge.send(
                    "VKWebAppAllowMessagesFromGroup",
                    {
                        group_id: LaunchParams.params.vk_group_id,
                        key: "" + data.bot_id,
                    }
                );

                if (resp.result === true || resp.result === 1) {
                    popout.loading();
                    setIsPending(true);

                    const payload: any = {
                        bot_id: data.bot_id,
                        hash: groupSiteData.getHash(),
                        isPageSource: !!pageId,
                    }

                    if (data.step && data.step.value) {
                        payload.step_id = data.step.value
                    }

                    const botResult = await BotService.run(payload);

                    setIsPending(false);
                    popout.close();

                    // Действие при успешном добавлении
                    if (botResult.success) {
                        let messagesUrl = `https://vk.com/im?sel=-${LaunchParams.params.vk_group_id}`;

                        // На всякий случай покажем моадьное окно с подробностями
                        popout.open(
                            <AlertSubscriptionSuccess
                                title="Успешно"
                                type="success"
                                desc="Скоро вам придет сообщение"
                                body={
                                    <Button
                                        href={messagesUrl}
                                        target="_blank"
                                        style={{ marginTop: 20, width: "100%" }}
                                        onClick={() => {
                                            popout.close();
                                        }}
                                        size="l"
                                        stretched
                                    >
                                        Перейти в диалог
                                    </Button>
                                }
                                onClose={() => {
                                    popout.close();
                                }}
                                actions={[]}
                            />
                        );
                        saveGoal("bot_add");
                        setIsDisabled(true);
                    } else {
                        popout.error(botResult.message);
                    }
                } else {
                    /**
                     * @TODO - обработать Отказ\Отмену
                     */
                }
            } else {
                /**
                 * @TODO - log error
                 */
            }
        };
    }

    const style = {
        opacity: isPending ? 0.7 : 1,
        backgroundColor: data.color,
        color: data.text_color,
    } as any;

    if (isDisabled) {
        style.backgroundColor = "#f1f1f1";
        style.color = "var(--vkui--color_icon_secondary)";
        style.opacity = 0.6
        style.pointerEvents = 'none'
    }

    if (isDisabledTimerBtn) {
        style.opacity = 0.6
        style.pointerEvents = 'none'
    }

    return (
        <Button
            size="l"
            disabled={isDisabled}
            style={style}
            onClick={isPending || isDisabled ? () => {} : action}
        >
            <div className="B-Cover__button-inner">
                <span className="B-Cover__button-text">
                    {data.text}
                </span>
                <span className="B-Cover__button-icon">
                    <Icon28ChevronRightOutline width={18} />
                </span>
            </div>
        </Button>
    );
};

export default PageButton;
