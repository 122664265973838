import React, { Component } from "react";
import { Div, Alert, PanelSpinner, Placeholder } from "@vkontakte/vkui";

import container from "../container";

import ReactPixel from "../includes/Metrics/ReactPixel";
import { YMInitializer } from "react-yandex-metrika";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import VkPixelCommon from "../includes/Metrics/VkPixelCommon";

import InfiniteScroll from "react-infinite-scroll-component";

import Subscription from "./Subscription";
import CancelAllButton from "./Controls/CancelAllButton";
import AlertSubscriptionSuccess from "./Modals/Alert/AlertSubscriptionSuccess";
import Banner from "./Elements/Banner";
import TextToHtml from "../includes/Helpers/TextToHtml";
import { GROUP_NOT_FOUND_MESSAGE } from "../includes/Constants";
import { Analytics } from "../includes/Metrics/Analytics";
import Logger from "../includes/Services/Logger";
import { Icon16Add, Icon28Messages } from "@vkontakte/icons";
import * as Sentry from "@sentry/react";

let _Subscriptions = container.get("SubscriptionsService");
let _Subscribes = container.get("SubscribeService");

type SubscriptionsProps = {
    subscription_id: number;
    UrlParams: any;
    user: any;
    group: any;
    setGroup: any;
    setHeight: any;
    changeRoute: any;
    mainPopout: any;
    location: any;
    setForceSubscriptionPage: any;
    setDefaultSubscription: any;
};
type SubscriptionsState = {
    subscription_id: number;
    items: any;
    loading: boolean;
    success: boolean;
    message: string;
    group_data: any;
    groupNotFoundMode: boolean;
    isMessagesAllowed: boolean;
    isFetching: boolean;
    hash: string;
};

class Subscriptions extends Component<SubscriptionsProps, SubscriptionsState> {
    logger: Logger;
    offset: number;
    hasMore: boolean;

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            loading: true,
            success: true,
            message: "",
            group_data: {},
            subscription_id: this.props.subscription_id,
            groupNotFoundMode: false,
            isMessagesAllowed: false,
            isFetching: false,
            hash: "",
        };

        this.logger = container.get("logger");
    }

    counter_params = {
        utm_source: this.props.UrlParams.params.utm_source,
        utm_medium: this.props.UrlParams.params.utm_medium,
        utm_campaign: this.props.UrlParams.params.utm_campaign,
        utm_term: this.props.UrlParams.params.utm_term,
        utm_content: this.props.UrlParams.params.utm_content,
        rs: this.props.UrlParams.params.rs,
        roistat: this.props.UrlParams.params.roistat,
        delivery_id: this.props.UrlParams.params.d,
        user_id: this.props.user.id,
        group_id: this.props.group.vk_group_id,
        subscription_id: this.props.subscription_id,
    };
    ya_options = {
        webvisor: true,
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        trackHash: true,
        params: this.counter_params,
    };
    admin_roles = ["moder", "editor", "admin"];

    componentDidMount() {
        this.offset = 0;
        this.hasMore = true;

        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        try {
            if (prevProps.subscription_id !== this.props.subscription_id) {
                this.offset = 0;
                this.hasMore = true;
                this.setState(
                    {
                        subscription_id: this.props.subscription_id,
                        items: [],
                        loading: true,
                    },
                    () => {
                        this.fetchData();
                    }
                );
            }
        } catch (e) {
            this.logger.error(
                { code: 9043, message: e.message },
                "runtime_error",
                "Subscriptions.jsx"
            );
            Sentry.captureException(e);
        }
    }

    fetchData() {
        try {
            const { setGroup, setHeight, UrlParams } = this.props;

            let data: any = {
                vk_group_id: this.props.group.vk_group_id,
                vk_user_id: this.props.user.id,
                params: this.props.UrlParams.params,
                offset: this.offset,
            };

            if (this.state.subscription_id) {
                data.subscription_id = this.state.subscription_id;
            }

            this.setState({ isFetching: true });

            _Subscriptions.getSubscriptions(data, (result) => {
                if (result.success) {
                    if (result.group) {
                        this.props.group.data = result.group;
                        setGroup && setGroup(result.group);
                    }
                    if (result.end) {
                        this.hasMore = false;
                    }
                    if (this.offset === 0) {
                        this.setState(
                            {
                                loading: false,
                                success: true,
                                isFetching: false,
                                items: this.state.items.concat(result.items),
                                group_data: result.group
                                    ? result.group
                                    : this.state.group_data,
                                subscription_id: result.subscription_id,
                                isMessagesAllowed: result.is_messages_allowed,
                                hash: result.hash,
                            },
                            () => {
                                // По умолчанию задана страница подписки
                                let defaultSubscriptionPageIsSet =
                                    !!result.subscription_id;

                                setTimeout(() => {
                                    setHeight &&
                                        setHeight(defaultSubscriptionPageIsSet);
                                }, 200);
                            }
                        );

                        let isSingle =
                            !!result.subscription_id && result.items.length > 0;

                        if (result.group.ga_metrica) {
                            ReactGA.initialize([
                                {
                                    trackingId: result.group.ga_metrica,
                                    gaOptions: {
                                        cookieFlags: "SameSite=None; Secure",
                                    },
                                },
                            ]);
                            ReactGA.send({
                                hitType: "pageview",
                                page: window.location.href,
                            });

                            if (isSingle) {
                                ReactGA.event({
                                    category: "Subscription",
                                    action: "View",
                                    label: result.subscription_id.toString(),
                                });
                            }
                        }
                        if (result.group.fb_pixel) {
                            let trackPageView = !!this.state.subscription_id;

                            ReactPixel.init(
                                result.group.fb_pixel,
                                {},
                                {
                                    autoConfig: true,
                                    debug: false,
                                },
                                trackPageView,
                                this.state.subscription_id
                            );
                        }

                        if (result.group.google_tag) {
                            try {
                                TagManager.initialize({
                                    gtmId: result.group.google_tag,
                                });

                                let title = "Главная страница";

                                if (isSingle) {
                                    // Страница подписки
                                    title = result.items[0].name;
                                }

                                Analytics.push({
                                    event: "pageview",
                                    pagePath: window.location.pathname,
                                    pageTitle: title,
                                });

                                if (isSingle) {
                                    const utms = UrlParams.getUtms();

                                    Analytics.push({
                                        event: `View`,
                                        vk_user_id: UrlParams.params.vk_user_id,
                                        ...utms,
                                    });

                                    Analytics.push({
                                        event: `View_${result.items[0].subscription_id}`,
                                        vk_user_id: UrlParams.params.vk_user_id,
                                        ...utms,
                                    });
                                }
                            } catch (e) {
                                this.logger.error(
                                    {
                                        code: 9044,
                                        message: e.message,
                                    },
                                    "runtime_error",
                                    "Subscriptions.jsx"
                                );
                                Sentry.captureException(e);
                                console.log(e);
                            }
                        }

                        const vkPixel = result.group.vk_pixel

                        if (vkPixel) {
                            if (isSingle) {
                                setTimeout(() => {
                                    VkPixelCommon.event(vkPixel, "View");
                                }, 50);

                                setTimeout(() => {
                                    VkPixelCommon.event(
                                        vkPixel,
                                        `View_${result.subscription_id}`
                                    );
                                }, 100);

                                setTimeout(() => {
                                    VkPixelCommon.goal(vkPixel, "page_view");
                                }, 150);
                            } else {
                                VkPixelCommon.event(vkPixel, "View_catalog");
                            }
                        }
                    } else {
                        this.setState(
                            {
                                success: true,
                                isFetching: false,
                                items: this.state.items.concat(result.items),
                            },
                            () => {
                                setTimeout(() => {
                                    setHeight && setHeight();
                                }, 200);
                            }
                        );
                    }
                    this.offset++;
                } else {
                    if (
                        result.error_code &&
                        parseInt(result.error_code) === 1
                    ) {
                        if (result.message === GROUP_NOT_FOUND_MESSAGE) {
                            // Сообщество не подключено в Senler
                            setGroup && setGroup(null);

                            this.setState({
                                groupNotFoundMode: true,
                                loading: false,
                                success: true,
                                isFetching: false,
                            });
                        } else {
                            this.props.changeRoute("landing");
                        }
                    } else {
                        this.props.mainPopout.error(result.message);
                    }
                }
            });
        } catch (e) {
            console.log(e);
            this.logger.error(
                { code: 9046, message: e.message },
                "runtime_error",
                "Subscriptions.jsx"
            );
            Sentry.captureException(e);
        }
    }

    cancelAllSubscriptions: any = () => {
        const { group, user, UrlParams, mainPopout } = this.props;

        mainPopout.loading();

        let hash = this.state.hash;

        let data = {
            vk_group_id: group.vk_group_id,
            vk_user_id: user.id,
            hash: hash,
            params: UrlParams.params,
        };

        _Subscribes.unsubscribeAll(data, (result) => {
            if (result.success) {
                this.markSubscriptionsAsUnsubscribed();

                mainPopout.open(
                    <AlertSubscriptionSuccess
                        type="cancel"
                        onClose={() => {
                            mainPopout.close();
                        }}
                        desc="Вы успешно отписались от всех рассылок"
                    />
                );
            } else {
                mainPopout.open(
                    <Alert
                        actionsLayout="vertical"
                        actions={[
                            {
                                title: "Повторить",
                                autoClose: true,
                                mode: "default",
                                action: () => {
                                    this.cancelAllSubscriptions();
                                },
                            },
                            {
                                title: "Отменить",
                                autoClose: true,
                                mode: "cancel",
                            },
                        ]}
                        onClose={() => {
                            mainPopout.close();
                        }}
                    >
                        <h2>Ошибка</h2>
                    </Alert>
                );
            }
        });
    };

    markSubscriptionsAsUnsubscribed = () => {
        const { items } = this.state;
        const newItems = items.map((i) => ({
            ...i,
            subscribed: false,
            paid: false,
            count: i.count - 1,
        }));
        this.setState({ items: newItems });
    };

    showUnsubscribeAllBtn = () => {
        // Добавить другую логику показа кнопки
        return true;
    };

    render() {
        try {
            const {
                groupNotFoundMode,
                loading,
                isFetching,
                items,
                subscription_id,
            } = this.state;
            const isSingle = !!subscription_id;

            // Если сообщество не подключено в Senler
            if (groupNotFoundMode) {
                return this.renderGroupNotFound();
            }

            if (loading) {
                return (
                    <PanelSpinner
                        size="regular"
                        className="vertical-centered-spinner"
                    />
                );
            }

            // Если заход на группу подписчиков и она не найдена
            if (this.state.subscription_id && items.length <= 0) {
                return this.renderSubscriptionNotFound();
            }

            const hasGroupData = Object.keys(this.state.group_data).length > 0;

            return (
                <div className="content--inner">
                    {this.state.group_data.ya_metrica ? (
                        <YMInitializer
                            accounts={[
                                parseInt(this.state.group_data.ya_metrica),
                            ]}
                            version="2"
                            options={this.ya_options}
                        />
                    ) : (
                        ""
                    )}
                    {items.length ? (
                        <>
                            {!this.state.subscription_id ? (
                                <>
                                    {hasGroupData && (
                                        <Banner
                                            type="catalog"
                                            platform={
                                                this.props.UrlParams.params
                                                    .vk_platform
                                            }
                                            banner_type={
                                                this.state.group_data
                                                    .banner_type
                                            }
                                            mobile_banner_type={
                                                this.state.group_data
                                                    .mobile_banner_type
                                            }
                                            banner_url={
                                                this.state.group_data.banner_url
                                            }
                                            mobile_banner_url={
                                                this.state.group_data
                                                    .mobile_banner_url
                                            }
                                            video_key={
                                                this.state.group_data.video_key
                                            }
                                            mobile_video_key={
                                                this.state.group_data
                                                    .mobile_video_key
                                            }
                                            video_autoplay={1}
                                            name={this.state.group_data.name}
                                        />
                                    )}

                                    <Div className="Subscriptions-header">
                                        <div className="Subscriptions-header__inner">
                                            {TextToHtml(
                                                this.state.group_data
                                                    .description
                                            )}
                                        </div>
                                    </Div>
                                </>
                            ) : (
                                ""
                            )}
                            <InfiniteScroll
                                dataLength={this.state.items.length} //This is important field to render the next data
                                next={() => {
                                    this.fetchData();
                                }}
                                hasMore={this.hasMore}
                                loader={isFetching ? <PanelSpinner /> : null}
                            >
                                {isSingle
                                    ? this.renderSingleSubscription()
                                    : this.renderCatalog()}
                            </InfiniteScroll>
                        </>
                    ) : (
                        <>
                            {this.admin_roles.indexOf(
                                this.props.UrlParams.params.vk_viewer_group_role
                            ) >= 0 ? (
                                // Placeholder для администратора, если не найдено групп подписчиков(подписок)
                                <Placeholder
                                    action={<></>}
                                    stretched
                                >
                                    <p>
                                        В сообществе пока нет групп <br />{" "}
                                        подписчиков, на которые смогут{" "}
                                        <br /> подписываться пользователи.
                                    </p>
                                </Placeholder>
                            ) : (
                                //  Placeholder для пользователя, если не найдено групп подписчиков(подписок)
                                <Placeholder
                                    action={
                                        <a
                                            className="btn-link"
                                            href={
                                                "https://vk.com/im?sel=-" +
                                                this.props.group.vk_group_id
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <Icon28Messages
                                                width={19}
                                                height={19}
                                            />
                                            Написать в поддержку
                                        </a>
                                    }
                                    stretched
                                >
                                    <p>
                                        В сообществе пока нет групп
                                        подписчиков.
                                    </p>
                                </Placeholder>
                            )}
                        </>
                    )}
                </div>
            );
        } catch (e) {
            console.log(e);
            this.logger.error(
                { code: 9047, message: e.message },
                "render_error",
                "Subscriptions.jsx"
            );
            Sentry.captureException(e);
            return null;
        }
    }

    renderSubscriptionNotFound() {
        const { location } = this.props;

        return (
            <Placeholder
                action={
                    <button
                        className="btn-link"
                        onClick={location.flushToCatalog}
                    >
                        Перейти в каталог
                    </button>
                }
                stretched
            >
                <p>Группа подписчиков не найдена</p>
            </Placeholder>
        );
    }

    renderCatalog() {
        const { items, subscription_id, group_data } = this.state;
        const { setForceSubscriptionPage, setHeight } = this.props;
        const isSingle = !!subscription_id;

        /**
         * rows - для отображения каталога строками
         * icons - для отображения каталога строками с иконками
         * tiles - для отроюражения каталога карточками
         */
        const catalogType = group_data.catalog_type
            ? group_data.catalog_type
            : "icons";
        const catalogClassName = `Subscriptions-catalog Subscriptions-catalog_${
            catalogType === "tiles" ? "tiles" : "rows"
        }`;

        return (
            <div>
                <div className={catalogClassName}>
                    {items.map((item) => {
                        return (
                            <Div
                                key={item.subscription_id}
                                className="Subscriptions-catalog__item"
                            >
                                <Subscription
                                    single={isSingle}
                                    mainPopout={this.props.mainPopout}
                                    key={item.subscription_id}
                                    UrlParams={this.props.UrlParams}
                                    group={this.props.group}
                                    user={this.props.user}
                                    opts={item}
                                    type={catalogType}
                                    hash={this.state.hash}
                                    setForceSubscriptionPage={
                                        setForceSubscriptionPage
                                    }
                                    setHeight={setHeight}
                                />
                            </Div>
                        );
                    })}
                </div>
                {this.showUnsubscribeAllBtn() && (
                    <CancelAllButton
                        onClick={this.cancelAllSubscriptions}
                        centered={true}
                    />
                )}
            </div>
        );
    }

    renderSingleSubscription() {
        const { items, group_data } = this.state;

        /**
         * rows - для отображения каталога строками
         * icons - для отображения каталога строками с иконками
         * tiles - для отроюражения каталога карточками
         */
        const catalogType = group_data.catalog_type
            ? group_data.catalog_type
            : "icons";

        return items.map((item) => {
            return (
                <Subscription
                    single={true}
                    mainPopout={this.props.mainPopout}
                    key={item.subscription_id}
                    UrlParams={this.props.UrlParams}
                    group={this.props.group}
                    user={this.props.user}
                    opts={item}
                    type={catalogType}
                    hash={this.state.hash}
                />
            );
        });
    }

    renderGroupNotFound() {
        return (
            <>
                {this.admin_roles.indexOf(
                    this.props.UrlParams.params.vk_viewer_group_role
                ) >= 0 ? (
                    <Placeholder
                        action={<></>}
                        stretched
                    >
                        <div>Сообщество не подключено</div>
                    </Placeholder>
                ) : (
                    //  Placeholder для пользователя, если не подключено сообщество
                    <Placeholder
                        action={
                            <a
                                className="btn-link"
                                href={
                                    "https://vk.com/im?sel=-" +
                                    this.props.group.vk_group_id
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Icon28Messages width={19} height={19} />
                                Написать в поддержку
                            </a>
                        }
                        stretched
                    >
                        <p>В сообществе пока нет групп подписчиков.</p>
                    </Placeholder>
                )}
            </>
        );
    }
}

export default Subscriptions;
