import React from "react";
import { DEFAULT_BLOCK_TEXT_FONT_SIZE } from "../../../includes/Constants/PageBlockConstants";
import { simpleTextToHtml } from "../../../includes/Helpers/TextToHtml";
import { BlockAlignment } from "../../../includes/Types/PageBlocks/IndentsAndAlignBlockPopup";
import vkIcons from "../../Elements/vkIcons";

type AdvantagesBlockType = {
    data: any;
    blockPadding?: number;
    platform: string;
    resizer?: any;
    blockAlignment?: BlockAlignment;
    textFontSize?: number;
};

const defaultResizer = {
    // Объект, управляющий высотой приложения на десктопе
    setLandingHeight: () => {},
};

const Advantages = (props: AdvantagesBlockType) => {
    const {
        data,
        resizer = defaultResizer,
        blockPadding = 40,
        blockAlignment = "center",
        textFontSize = DEFAULT_BLOCK_TEXT_FONT_SIZE
    } = props;

    const imgRenderer = () => {
        resizer.setLandingHeight();
    };

    const imgStartRenderer = () => {
        requestAnimationFrame(imgRenderer);
    };

    const renderItems = () => {
        if (!data.items) return null;

        return data.items.map((item) => {
            return (
                <div key={item.uuid} className="B-Advantages__item">
                    {item.img && item.img.url
                        ? renderImage(item.img, item.item_radius)
                        : null}
                    {item.svg_icon_id ? (
                        <div className="B-Advantages__svgIconWrapper">
                            <div
                                className="B-Advantages__svgIcon"
                                style={{
                                    background: item.svg_icon_bg_color
                                        ? item.svg_icon_bg_color
                                        : "var(--vkui--color_write_bar_icon)",
                                    color: item.svg_icon_color
                                        ? item.svg_icon_color
                                        : "var(--vkui--color_text_accent)",
                                    borderRadius:
                                        item.item_radius ||
                                        item.item_radius === 0
                                            ? `${item.item_radius / 2}%`
                                            : `${100 / 2}%`,
                                }}
                            >
                                {
                                    vkIcons.find(
                                        (el) => el.id === item.svg_icon_id
                                    ).icon
                                }
                            </div>
                        </div>
                    ) : null}
                    <div className="B-Advantages__item-title">
                        {item.title}
                    </div>
                    <div className="B-Advantages__item-text">
                        {simpleTextToHtml(item.text)}
                    </div>
                </div>
            );
        });
    };

    const renderImage = (itemImage: { url: string }, itemRadius: number) => {
        return (
            <div className="B-Advantages__item-image">
                <div
                    className="B-Advantages__item-image-wrapper"
                    style={{
                        borderRadius:
                            itemRadius || itemRadius === 0
                                ? `${itemRadius / 2}%`
                                : `${100 / 2}%`,
                    }}
                >
                    <img
                        src={itemImage.url}
                        alt=""
                        onLoad={(e) => {
                            requestAnimationFrame(imgStartRenderer);
                        }}
                    />
                </div>
            </div>
        );
    };

    return (
        <div
            className={`B-Block B-Advantages ${blockAlignment}`}
            style={{
                paddingTop: `${blockPadding}px`,
                paddingBottom: `calc(${blockPadding}px - 20px)`,
                fontSize: `${textFontSize}em`,
            }}
            data-testid="b-advantages"
        >
            {data.has_title === undefined || data.has_title ? (
                <div className="B-Block__head">
                    {data.content.title && (
                        <div className="B-Block__title">
                            {data.content.title}
                        </div>
                    )}
                    {data.content.text && (
                        <div className="B-Block__subtitle">
                            {simpleTextToHtml(data.content.text)}
                        </div>
                    )}
                </div>
            ) : null}

            <div
                className={`B-Advantages__items B-Advantages__items-wrapper-${data.id}`}
            >
                {renderItems()}
            </div>
        </div>
    );
};

export default Advantages;
