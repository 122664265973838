import React, { useReducer, useContext, useEffect, useState } from "react";
import {
    Input,
    Div,
    Checkbox,
    Button,
    NativeSelect,
    FormItem,
} from "@vkontakte/vkui";
import InputDynamic from "../../../../Controls/InputDynamic";
import SubscriptionSelect from "../../../../Controls/Selects/SubscriptionSelect";
import ColorPicker from "../../../../Controls/ColorPicker";
import initialState from "./state";
import reducer from "./reducer";
import { pagesButtonsActionTypes } from "../../../../../includes/Constants";
import { Context } from "../../../../../context";
import LeadButtonNotify from "../../../../Elements/LeadButtonNotify";
import FadeIn from "react-fade-in";
import { isHex } from "../../../../../includes/Helpers/Helpers";
import { Icon28ChevronRightOutline } from "@vkontakte/icons";
import BotSelect from "./BotSelect";

type ButtonEditProps = {
    data: any;
    errors: any;
    currentDynamicInput: string;
    onChange: Function;
    timerFixedEdit?: boolean;
    isInModal: boolean;
};

const DEFAULT_BUTTON_TEXT_COLORS = [
    { value: "var(--vkui--color_text_primary)", isNewColor: false },
    { value: "var(--vkui--color_background_modal)", isNewColor: false },
];

const DEFAULT_BUTTON_BACKGROUND_COLORS = [
    { value: "var(--vkui--color_accent_blue)", isNewColor: false },
    { value: "var(--vkui--color_accent_gray)", isNewColor: false },
    { value: "var(--vkui--color_accent_green)", isNewColor: false },
    { value: "var(--vkui--color_accent_orange)", isNewColor: false },
    { value: "var(--vkui--color_accent_purple)", isNewColor: false },
    { value: "var(--vkui--color_accent_red)", isNewColor: false },
    { value: "var(--vkui--color_accent_violet)", isNewColor: false },
];

const ButtonCommonForm = (props: ButtonEditProps) => {
    const {
        data,
        currentDynamicInput,
        onChange,
        errors,
        timerFixedEdit,
        isInModal,
    } = props;

    const { subscriptions, groupSiteData, bots } = useContext(Context);
    const admins = groupSiteData.getAdmins();
    const botsList = bots.get();

    const subscriptions_groups = subscriptions.get();

    const default_lead_admin_id =
        admins.length > 0
            ? data.lead_admin
                ? data.lead_admin
                : admins[0].id
            : 0;

    const default_bot_id = data.bot_id
        ? data.bot_id
        : botsList.length > 0
        ? botsList[0].id
        : 0;

    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        button: { ...initialState.button, ...data },
    });

    const [btnPickerBgColor, setBtnPickerBgColor] = useState(
        isHex(state.button.color) ? state.button.color : "#3f8ae0"
    );

    const [btnPickerTextColor, setBtnPickerTextColor] = useState(
        isHex(state.button.text_color) ? state.button.text_color : "#ffffff"
    );

    useEffect(() => {
        if (state.button.action === "lead") {
            dispatch({
                type: "set-lead-admin",
                payload: default_lead_admin_id,
            });
        }
        if (state.button.action === "bot") {
            dispatch({ type: "set-button-bot-id", payload: default_bot_id });
        }
    }, [state.button.action]);

    useEffect(() => {
        onChange(state.button);
    }, [state]);

    const handleBgColorChange = (color) => {
        dispatch({ type: "set-color", payload: color.value });
    };

    const handleTextColorChange = (color) => {
        dispatch({ type: "set-text-color", payload: color.value });
    };

    const renderLeadDetails = () => {
        return (
            <div style={{ marginBottom: !isInModal ? 8 : 12 }}>
                <FormItem
                    top="Выберите администратора"
                    status={!!errors.lead_admin ? "error" : "default"}
                >
                    <NativeSelect
                        name="lead_admin"
                        value={
                            state.button.lead_admin
                                ? state.button.lead_admin
                                : default_lead_admin_id
                        }
                        onChange={(e) => {
                            dispatch({
                                type: "set-lead-admin",
                                payload: e.currentTarget.value,
                            });
                        }}
                    >
                        {groupSiteData.getAdmins().map((admin) => {
                            return (
                                <option key={admin.id} value={admin.id}>
                                    {admin.fullname}
                                </option>
                            );
                        })}
                    </NativeSelect>
                </FormItem>
                <Checkbox
                    checked={!!state.button.send_trigger}
                    onChange={(e) => {
                        dispatch({
                            type: "set-send-trigger",
                            payload: e.currentTarget.checked,
                        });
                    }}
                >
                    Отправлять событие в триггер
                </Checkbox>
            </div>
        );
    };

    const getSubhead = (text: string) => {
        return (
            <div style={{ marginBottom: 8 }} className="subhead">
                {text}
            </div>
        );
    };

    return (
        <>
            <Div style={{ paddingTop: 6 }}>
                <InputDynamic
                    currentOpened={currentDynamicInput}
                    type="text"
                    status={errors.text ? "error" : "default"}
                    errorText={errors.text}
                    bottomText={state.errors.text ? state.errors.text : ""}
                    top="Текст кнопки"
                    value={state.button.text}
                    placeholder="Введите текст кнопки"
                    name="text"
                    useVariables={true}
                    variablesMode="pages"
                    onChange={(value) => {
                        dispatch({ type: "text", payload: value });
                    }}
                />
            </Div>

            {state.button.action === "subscribe" && (
                <Div style={{ paddingTop: 0 }}>
                    <InputDynamic
                        currentOpened={currentDynamicInput}
                        type="text"
                        status={errors.unsubscribe_text ? "error" : "default"}
                        errorText={errors.unsubscribe_text}
                        bottomText={state.errors.unsubscribe_text ? state.errors.unsubscribe_text : ""}
                        top="Текст кнопки отписки"
                        value={state.button.unsubscribe_text}
                        placeholder="Введите текст кнопки"
                        name="unsubscribe_text"
                        useVariables={true}
                        variablesMode="pages"
                        onChange={(value) => {
                            dispatch({ type: "set-unsubscribe-text", payload: value });
                        }}
                    />
                </Div>
            )}

            <Div>
                {getSubhead("Цвет кнопки")}
                <ColorPicker
                    value={state.button.color}
                    defaultNewColorValue="#3f8ae0"
                    pickerColor={btnPickerBgColor}
                    setPickerColor={setBtnPickerBgColor}
                    defaultColors={DEFAULT_BUTTON_BACKGROUND_COLORS}
                    onSelect={handleBgColorChange}
                    inputName="text-color"
                />
            </Div>

            <Div>
                {getSubhead("Цвет текста")}
                <ColorPicker
                    value={state.button.text_color}
                    defaultNewColorValue="#ffffff"
                    pickerColor={btnPickerTextColor}
                    setPickerColor={setBtnPickerTextColor}
                    defaultColors={DEFAULT_BUTTON_TEXT_COLORS}
                    onSelect={handleTextColorChange}
                    inputName="bg-color"
                />
            </Div>

            <Div>
                {getSubhead("Превью")}
                <Button
                    size="l"
                    style={{
                        color: state.button.text_color,
                        backgroundColor: state.button.color,
                        marginLeft: 0,
                        pointerEvents: "none",
                    }}
                >
                    <div className="B-Cover__button-inner">
                        <span className="B-Cover__button-text">
                            {data.text}
                        </span>
                        <span className="B-Cover__button-icon">
                            <Icon28ChevronRightOutline width={18} />
                        </span>
                    </div>
                </Button>
            </Div>

            <div
                style={{
                    marginBottom:
                        state.button.action === "join_community" && !isInModal
                            ? 67
                            : 15,
                }}
            >
                <Div
                    style={{
                        paddingBottom:
                            state.button.action === "join_community"
                                ? "var(--vkui--size_base_padding_vertical--regular)"
                                : 0,
                    }}
                >
                    {getSubhead("Действие для кнопки")}
                    <NativeSelect
                        name="type"
                        value={state.button.action}
                        onChange={(e) => {
                            dispatch({
                                type: "change-type",
                                payload: e.currentTarget.value,
                            });
                        }}
                    >
                        {pagesButtonsActionTypes.map((b) => {
                            return (
                                <option key={b.type} value={b.type}>
                                    {b.title}
                                </option>
                            );
                        })}
                    </NativeSelect>
                </Div>
            </div>

            {state.button.action === "url" ? (
                <div style={{ marginBottom: !isInModal ? 67 : 15 }}>
                    <FormItem
                        top="Адрес ссылки"
                        bottom={errors.url ? errors.url : ""}
                        status={errors.url ? "error" : "default"}
                    >
                        <Input
                            type="text"
                            name="url"
                            placeholder="Введите ссылку"
                            value={state.button.url}
                            onChange={(e) => {
                                dispatch({
                                    type: "change-url",
                                    payload: e.currentTarget.value,
                                });
                            }}
                        />
                    </FormItem>
                </div>
            ) : null}

            {state.button.action === "subscribe" ? (
                <div style={{ marginBottom: !isInModal ? 67 : 15 }}>
                    <FormItem
                        style={{
                            paddingLeft: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                        }}
                    >
                        <SubscriptionSelect
                            top="Выберите группу подписчиков"
                            hasError={!!errors.subscription}
                            defaultValue={
                                state.button.subscription_id
                                    ? state.button.subscription_id
                                    : ""
                            }
                            items={subscriptions_groups}
                            onSelect={(value) => {
                                dispatch({
                                    type: "button-subscription-id",
                                    payload: {
                                        subscription_id:
                                            value.data.subscription_id,
                                        button_text: value.data.btn_ok,
                                    },
                                });
                            }}
                        />
                    </FormItem>
                </div>
            ) : null}

            {
                state.button.action === "bot"
                    ? <BotSelect
                            isInModal={isInModal}
                            errors={errors}
                            state={state}
                            dispatch={dispatch}
                            botsList={botsList}
                        />
                    : null
            }

            {state.button.action === "lead" ? renderLeadDetails() : null}
            {state.button.action === "lead" && state.button.send_trigger ? (
                <FadeIn delay={10}>
                    <Div style={{ paddingTop: 0 }}>
                        <LeadButtonNotify />
                    </Div>
                </FadeIn>
            ) : null}

            {timerFixedEdit && (
                <Div
                    style={{ paddingLeft: 0, paddingRight: 0, marginTop: -25 }}
                >
                    <Checkbox
                        defaultChecked={state.button.hide_button}
                        onChange={(e) => {
                            dispatch({
                                type: "set-hide-button",
                                payload: e.target.checked,
                            });
                        }}
                    >
                        Скрывать кнопку при истечении таймера
                    </Checkbox>
                </Div>
            )}
        </>
    );
};

export default ButtonCommonForm;
